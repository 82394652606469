import {
  ApppointmentTabTypes,
  ApppointmentTabTypesEnum,
  Auth,
  FindDashboardSummary,
} from '@/@types';
import { env } from '@/constants';
import { DateHelper } from '@/helper';
import { eventAnalytics } from '@/providers';
import { TRACK_EVENTS } from '@/providers/eventAnalytics/trackEvents';
import { Variants, motion } from 'framer-motion';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { HomePageCard, HomePageCardProps } from '.';
import { NeedAnythingCard, withPermission } from '..';

const container: Variants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      stiffness: 50,
      staggerChildren: 0.1,
    },
  },
};

const itemVariant: Variants = {
  show: { opacity: 1 },
  hidden: { opacity: 0 },
};

type Props = {
  summary?: FindDashboardSummary;
};

export function HomeCards({ summary }: Props) {
  const hasGap = useMemo(() => {
    return (
      !summary?.appointments?.future &&
      !summary?.appointments.previous &&
      !summary?.imagingResults &&
      !summary?.labResults &&
      !summary?.medicationsList?.length &&
      !summary?.favoriteDocument
    );
  }, [summary]);

  const NeedAnyCard = withPermission(
    NeedAnythingCard,
    Auth.Permissions.UserPermissions.CONTACT_CARE_TEAM
  );

  return (
    <motion.div
      initial='hidden'
      animate='show'
      variants={container}
      className='grid grid-cols-1 lg:grid-cols-2 justify-start gap-6'
    >
      {summary?.appointments?.future && (
        <motion.div variants={itemVariant}>
          <AppointmentCard
            type={ApppointmentTabTypesEnum.UPCOMING}
            appointment={summary?.appointments?.future}
          />
        </motion.div>
      )}
      {summary?.appointments.previous && (
        <motion.div variants={itemVariant}>
          <AppointmentCard
            type={ApppointmentTabTypesEnum.PAST}
            appointment={summary?.appointments?.previous}
          />
        </motion.div>
      )}
      {summary?.imagingResults && (
        <motion.div variants={itemVariant}>
          <ResultCard type='imaging' result={summary.imagingResults} />
        </motion.div>
      )}
      {summary?.labResults && (
        <motion.div variants={itemVariant}>
          <ResultCard type='lab' result={summary.labResults} />
        </motion.div>
      )}
      {!!summary?.medicationsList?.length && (
        <motion.div variants={itemVariant}>
          <MedicationCard medications={summary?.medicationsList} />
        </motion.div>
      )}
      {summary?.favoriteDocument && (
        <motion.div variants={itemVariant}>
          <DocumentCard document={summary?.favoriteDocument} />
        </motion.div>
      )}
      <NeedAnyCard widthFull={hasGap} />
    </motion.div>
  );
}

function AppointmentCard({
  type,
  appointment,
}: {
  type: ApppointmentTabTypes;
  appointment: {
    start: string;
    title: string;
    providerName: string;
  };
}) {
  const navigate = useNavigate();
  const category = useMemo(() => {
    if (type === ApppointmentTabTypesEnum.PAST) {
      return 'Previous appoinment';
    }
    if (type === ApppointmentTabTypesEnum.UPCOMING) {
      return 'Upcoming appointment';
    }
    return '';
  }, [type]);
  const date = DateHelper.formatDateTimeToDisplay(DateTime.fromISO(appointment.start!));
  const buttons = useMemo((): HomePageCardProps['buttons'] => {
    if (type === ApppointmentTabTypesEnum.PAST) {
      return [
        {
          variant: 'tertiary',
          label: 'View all',
          onClick: () => {
            eventAnalytics.track(TRACK_EVENTS.HOME_PAGE_CARD_CLICKED, {
              cardName: 'Previous appointments',
              buttonClicked: 'View all',
            });
            if (env.APP_FEATURE_FLAGS.IS_TO_SHOW_NEW_APPOINTMENTS_PAGE) {
              return navigate('/appointments?tab=past');
            }
            return navigate('/appointments/previous');
          },
        },
      ];
    }
    if (type === ApppointmentTabTypesEnum.UPCOMING) {
      return [
        {
          variant: 'tertiary',
          label: 'View all',
          onClick: () => {
            eventAnalytics.track(TRACK_EVENTS.HOME_PAGE_CARD_CLICKED, {
              cardName: 'Upcoming appointments',
              buttonClicked: 'View all',
            });
            if (env.APP_FEATURE_FLAGS.IS_TO_SHOW_NEW_APPOINTMENTS_PAGE) {
              return navigate('/appointments?tab=upcoming');
            }
            return navigate('/appointments/upcoming');
          },
        },
      ];
    }
    return [];
  }, [navigate, type]);

  const Card = withPermission(HomePageCard, Auth.Permissions.UserPermissions.VIEW_APPOINTMENTS);

  return (
    <Card
      category={category}
      title={appointment?.title || ''}
      descriptions={[appointment?.providerName || '', date]}
      buttons={buttons}
    />
  );
}

function ResultCard({
  type,
  result,
}: {
  type: 'imaging' | 'lab';
  result: { id: number | string; description: string; createdDateTime: string };
}) {
  const navigate = useNavigate();
  const date = DateHelper.formatDateToDisplay(DateTime.fromISO(result.createdDateTime));
  const buttons = useMemo((): HomePageCardProps['buttons'] => {
    if (type === 'imaging') {
      return [
        {
          variant: 'primary',
          label: 'View details',
          onClick: () => {
            eventAnalytics.track(TRACK_EVENTS.HOME_PAGE_CARD_CLICKED, {
              cardName: 'Imaging records',
              buttonClicked: 'View details',
              resultId: result.id,
            });
            return navigate(`/results/imaging/${result.id}`);
          },
        },
        {
          label: 'View all',
          onClick: () => {
            eventAnalytics.track(TRACK_EVENTS.HOME_PAGE_CARD_CLICKED, {
              cardName: 'Imaging records',
              buttonClicked: 'View all',
            });
            return navigate('/results/imaging');
          },
        },
      ];
    }
    if (type === 'lab') {
      return [
        {
          variant: 'primary',
          label: 'View details',
          onClick: () => {
            eventAnalytics.track(TRACK_EVENTS.HOME_PAGE_CARD_CLICKED, {
              cardName: 'Lab records',
              buttonClicked: 'View details',
              resultId: result.id,
            });
            return navigate(`/results/lab/${result.id}`);
          },
        },
        {
          label: 'View all',
          onClick: () => {
            eventAnalytics.track(TRACK_EVENTS.HOME_PAGE_CARD_CLICKED, {
              cardName: 'Lab records',
              buttonClicked: 'View all',
            });
            return navigate('/results/lab');
          },
        },
      ];
    }
    return [];
  }, [navigate, result.id, type]);
  const category = useMemo(() => {
    if (type === 'imaging') {
      return 'Imaging results';
    }
    if (type === 'lab') {
      return 'Lab results';
    }
    return '';
  }, [type]);

  const Card = withPermission(HomePageCard, Auth.Permissions.UserPermissions.VIEW_RESULTS);

  return (
    <Card category={category} title={result.description} descriptions={[date]} buttons={buttons} />
  );
}

function MedicationCard({ medications }: { medications: Array<{ medication: string }> }) {
  const navigate = useNavigate();
  const Card = withPermission(HomePageCard, Auth.Permissions.UserPermissions.VIEW_MEDICATIONS);

  return (
    <Card
      category='Medications'
      list={medications?.map((m) => m.medication)}
      buttons={[
        {
          label: 'View all',
          onClick: () => {
            eventAnalytics.track(TRACK_EVENTS.HOME_PAGE_CARD_CLICKED, {
              cardName: 'Medications',
              buttonClicked: 'View all',
            });
            return navigate('/medications');
          },
        },
      ]}
    />
  );
}

function DocumentCard({
  document,
}: {
  document: { id: number | string; documentName: string; createdAt: string };
}) {
  const navigate = useNavigate();
  const date = DateHelper.formatDateToDisplay(DateTime.fromISO(document.createdAt));

  return (
    <HomePageCard
      category='Documents'
      title={document.documentName}
      descriptions={[date]}
      buttons={[
        {
          label: 'View details',
          onClick: () => {
            eventAnalytics.track(TRACK_EVENTS.HOME_PAGE_CARD_CLICKED, {
              cardName: 'Documents',
              buttonClicked: 'View details',
              documentId: document.id,
            });
            return navigate(`/documents/${document.id}`);
          },
          variant: 'primary',
        },
        {
          label: 'View all',
          onClick: () => {
            eventAnalytics.track(TRACK_EVENTS.HOME_PAGE_CARD_CLICKED, {
              cardName: 'Documents',
              buttonClicked: 'View all',
            });
            return navigate('/documents');
          },
        },
      ]}
    />
  );
}
