import { cn, MaterialIcons } from '@lib-atria/ui-toolkit';

type Props = {
  label: string;
  hasNotes?: boolean;
  className?: string;
};

export function ScoreCardCell({ label, hasNotes, className }: Props) {
  return (
    <div className={cn('flex rounded-full px-4 bg-product-sand-400 items-center', className)}>
      {hasNotes ? (
        <div className='flex items-center gap-1 cursor-pointer'>
          <MaterialIcons name='notes' size='medium' />
          <span className='text-neue-kelp-400 text-sm font-medium leading-4 -tracking-[0.35px]'>
            {label}
          </span>
        </div>
      ) : (
        <span className='text-neue-kelp-400 text-sm font-medium leading-4 -tracking-[0.35px] py-1'>
          {label}
        </span>
      )}
    </div>
  );
}
