import ContentLoader from 'react-content-loader';
import { useMediaQuery } from 'react-responsive';

export function ScoreCardSummarySkeletonLoader() {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  return (
    <ContentLoader
      speed={2}
      width='100%'
      height='100%'
      viewBox={isMobile ? '0 0 100 90' : '0 0 100 77.5'}
      backgroundColor='#98B2A8'
      foregroundColor='#273933'
      preserveAspectRatio='xMinYMin meet'
    >
      {isMobile ? (
        <>
          <rect x='21%' y='25%' rx='1' ry='1' width='25' height='70%' />
          <rect x='47%' y='55%' rx='1' ry='1' width='25' height='40%' />
        </>
      ) : (
        <>
          <rect x='26%' y='43%' rx='1' ry='1' width='24' height='57%' />
          <rect x='50.5%' y='78%' rx='1' ry='1' width='24' height='22%' />
        </>
      )}
    </ContentLoader>
  );
}
