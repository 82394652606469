import { useContactDialogContext } from '@/contexts';
import { eventAnalytics } from '@/providers';
import { TRACK_EVENTS } from '@/providers/eventAnalytics/trackEvents';
import { Button, cn } from '@lib-atria/ui-toolkit';
import { useCallback } from 'react';
import { TextLayout } from '..';

type Props = {
  widthFull?: boolean;
};

export function NeedAnythingCard({ widthFull = true }: Props) {
  const { setContactDialogVisibility, setContent } = useContactDialogContext();

  const handleOpenContactCareButton = useCallback(() => {
    setContactDialogVisibility(true);
    setContent(null);
    eventAnalytics.track(TRACK_EVENTS.HOME_PAGE_CARD_CLICKED, {
      cardName: 'We are here to help',
      buttonClicked: 'Contact care team',
    });
  }, [setContactDialogVisibility, setContent]);

  return (
    <div
      className={cn(
        'w-full min-h-[237px] flex flex-col shadow-none bg-neue-kelp-400 py-5 px-7 rounded-lg',
        widthFull && 'col-span-2 row-span-2'
      )}
    >
      <div className='flex-1'>
        <p className={cn(TextLayout.callout2, 'text-white  grow')}>
          We are here to help with any questions or concerns
        </p>
      </div>
      <Button
        label='Contact care team'
        theme='light'
        size='large'
        variant='primary'
        className='w-full'
        onClick={handleOpenContactCareButton}
      />
    </div>
  );
}
