import { cn } from '@lib-atria/ui-toolkit';
import { AnimatePresence, cubicBezier, motion } from 'framer-motion';
import { useCallback, useState } from 'react';

import { MaterialIcons, SidebarVariants, StaggerChildren, TextLayout } from '@/components';

type Props = {
  variant?: SidebarVariants;
  label: string;
  onClick?: VoidFunction;
  children?: React.ReactNode;
};

export function SidebarMobileListItem({ variant, label, onClick, children }: Props) {
  const [open, setOpen] = useState(false);
  const hasSubItems = !!children;

  const toggleSubItems = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  return (
    <>
      <div
        className='w-full flex items-center justify-center gap-1 cursor-pointer'
        onClick={hasSubItems ? toggleSubItems : onClick}
      >
        <div className='flex items-center justify-center'>
          <p
            id={`${label?.toLowerCase()?.replace(/ /g, '')}Button`}
            className={cn(
              TextLayout.callout1,
              variant === 'forest' && 'text-white',
              variant === 'gray' && 'text-product-forest-100'
            )}
          >
            {label}
          </p>
        </div>
        {hasSubItems && (
          <motion.i
            initial={open ? 'open' : 'closed'}
            variants={{
              open: { rotate: '180deg', opacity: 1 },
              closed: { rotate: '0deg', opacity: 1 },
            }}
            transition={{ duration: 0.5, ease: cubicBezier(0.65, 0, 0.35, 1) }}
            animate={open ? 'open' : 'closed'}
          >
            <MaterialIcons
              name='expand_more'
              className={cn(
                'text-xl leading-8',
                variant === 'forest' && 'text-product-sand-100/50',
                variant === 'gray' && 'text-product-forest-100'
              )}
            />
          </motion.i>
        )}
      </div>
      <AnimatePresence>
        {open && (
          <motion.div
            initial='closed'
            animate='open'
            exit='closed'
            variants={{
              open: { y: 0, opacity: 1 },
              closed: { y: -20, opacity: 0 },
            }}
            transition={{ duration: 0.1, ease: cubicBezier(0.65, 0, 0.35, 1) }}
            className='w-full'
          >
            <StaggerChildren duration={0.2} className='w-full flex flex-col gap-4'>
              {children}
            </StaggerChildren>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}
