import { DateTime } from 'luxon';
import { cn, TextLayout } from '@lib-atria/ui-toolkit';
import { Appointments, Doctor } from '@/@types';
import { UpcomingAppointmentsLocation } from '../upcomingAppointments';
import { useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { CareTeamAbout, CareTeamAboutMobile } from '../careTeam';
import { env } from '@/constants';
import { LocationType } from '@/config/locationsData';

type Props = {
  className?: string;
  doctor?: Appointments.FindAllPatientsAppointments.Doctor;
  lastModified: string | null;
  groupItem: Appointments.FindAllPatientsAppointments.GroupItem;
  appointmentDate: string;
};

const fallbackDoctorImageMap: Record<string, string> = {
  'New York': 'atria_new_york',
  'Palm Beach': 'atria_palm_beach',
};

export function AppointmentsCardSchedule({
  lastModified,
  groupItem,
  className,
  doctor,
  appointmentDate,
}: Props) {
  const [isVisibleAbout, setIsVisibleAbout] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [appointments, setAppointments] = useState<
    Appointments.FindAllPatientsAppointments.GroupItemAppointment[]
  >([]);
  const doctorName = useMemo(
    () =>
      doctor?.degree === 'MD'
        ? `${doctor.profession} ${doctor.name}`
        : `${doctor?.name}, ${doctor?.degree}`,
    [doctor]
  );

  const doctorImage = useMemo(() => {
    if (doctor?.photo) return doctor.photo;

    const randomNumber =
      Math.floor(Math.random() * (groupItem.location === LocationType.PALM_BEACH ? 7 : 6)) + 1;

    if (!fallbackDoctorImageMap[groupItem.location])
      return `${env.APP_CDN_URL}/images/stones/stone_card_details.png`;

    return `${env.APP_CDN_URL}/images/records/${fallbackDoctorImageMap[groupItem.location]}_${randomNumber}.png`;
  }, [groupItem.location, doctor?.photo]);

  const isLocationVariant = useMemo(
    () => ['Telehealth', 'Home', 'Phone'].every((type) => type !== groupItem.location),
    [groupItem.location]
  );

  useEffect(() => {
    setAppointments(
      [...groupItem.appointments].sort(
        (apptA, apptB) => new Date(apptA.date).getTime() - new Date(apptB.date).getTime()
      )
    );
  }, [groupItem.appointments]);

  return (
    <div
      className={cn(
        'flex flex-col items-start justify-start gap-5 rounded-xl bg-white px-5 py-8 lg:px-9 lg:py-8 lg:gap-[18px]',
        className
      )}
    >
      <div className='h-[0.5px] w-full bg-neue-kelp-400'></div>
      <div className='flex flex-col gap-[50px] lg:flex-row lg:gap-0 w-full'>
        <h2
          className={cn(
            isMobile ? TextLayout.h2.scto : TextLayout.callout1,
            'text-neue-kelp-400 lg:w-[27%] w-full'
          )}
        >
          Details
        </h2>

        <div className='inline-flex lg:w-[73%] w-full flex-col items-end justify-start gap-12 lg:gap-11'>
          {doctor && (
            <div
              className={cn(
                'inline-flex flex-col items-start justify-between self-stretch sm:flex-row lg:gap-6 lg:pb-7 lg:pt-0.5 sm:gap-4',
                {
                  hidden: isMobile && !doctor,
                }
              )}
            >
              {doctor && (
                <div className='inline-flex w-full flex-col items-start justify-start max-w-[232px]'>
                  <p className={cn(TextLayout.callout3, 'text-neue-kelp-400')}>
                    Your appointment is with {doctorName}
                  </p>

                  <div
                    onClick={() => setIsVisibleAbout(true)}
                    className='cursor-pointer self-stretch text-xl font-medium leading-7 text-gray-500 underline underline-offset-2 tracking-[-0.2px]'
                    style={{
                      textUnderlinePosition: 'from-font',
                    }}
                  >
                    Bio
                  </div>
                </div>
              )}

              <div></div>

              <div
                className={cn(
                  'inline-flex w-full flex-col items-center justify-end lg:pt-1 sm:max-w-[292px] lg:w-[292px] mt-4 sm:mt-0',
                  {
                    hidden: !isLocationVariant && isMobile && !doctor?.photo,
                  }
                )}
              >
                <img className='w-full' src={doctorImage} />
              </div>
            </div>
          )}

          <div className='flex flex-col items-start justify-start gap-5 self-stretch'>
            {doctor && <div className='h-[0.5px] w-full bg-neue-kelp-400'></div>}

            <UpcomingAppointmentsLocation
              appointmentGroup={groupItem}
              appointmentDate={appointmentDate}
            />
          </div>

          <div className='flex flex-col items-start justify-start gap-5 self-stretch'>
            <div className='h-[0.5px] self-stretch bg-neue-kelp-400'></div>
            <h3
              className={cn(
                isMobile ? TextLayout.h3.ivar : TextLayout.h4.ivar,
                'text-neue-kelp-400'
              )}
            >
              Schedule
            </h3>
            <div className='flex flex-col items-start justify-start self-stretch'>
              {appointments.map((appointment, index) => (
                <div
                  key={`appointment-${appointment.id}-${index}`}
                  className='inline-flex items-start justify-start gap-5 self-stretch border-b border-gray-200 py-3 lg:py-2 lg:border-0'
                >
                  <div className='flex shrink grow basis-0 items-center justify-start gap-6'>
                    <div
                      className={cn(
                        TextLayout.callout3,
                        isLocationVariant ? 'text-neue-kelp-400' : 'text-gray-500',
                        'lg:text-gray-500'
                      )}
                    >
                      {appointment.title}
                    </div>
                  </div>
                  <div className={cn(TextLayout.callout3, 'text-gray-500')}>
                    {appointment.duration}m
                  </div>
                </div>
              ))}

              {lastModified && (
                <div className='inline-flex items-start justify-start gap-5 self-stretch py-3 lg:py-2'>
                  <div className='flex shrink grow basis-0 items-center justify-start gap-6'>
                    <div className={cn(TextLayout.callout3, ' text-gray-500')}>
                      Your schedule was last updated on{' '}
                      {DateTime.fromISO(lastModified).toFormat('LLL d, yyyy')}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {doctor && (
        <>
          {isMobile ? (
            <CareTeamAboutMobile
              key={doctor.id}
              doctor={doctor as unknown as Doctor}
              isVisible={isVisibleAbout}
              setIsVisible={setIsVisibleAbout}
            />
          ) : (
            <CareTeamAbout
              key={doctor.id}
              doctor={doctor as unknown as Doctor}
              isVisible={isVisibleAbout}
              setIsVisible={setIsVisibleAbout}
            />
          )}
        </>
      )}
    </div>
  );
}
