import { cn, TextLayout } from '@lib-atria/ui-toolkit';
import { motion } from 'framer-motion';
import { DateTime } from 'luxon';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useMediaQuery } from 'react-responsive';

import { Appointments } from '@/@types';
import { AppointmentsCardDateSidebar } from '../appointmentsCard';

export type UpcomingAppointmentsSidebarListRef = {
  setKey: (key: string) => void;
};

type Props = {
  items: Appointments.SidebarList.Item[];
  onClick: (sidebarItem: Appointments.SidebarList.Item) => void;
};

export const AppointmentsSidebarList = forwardRef<UpcomingAppointmentsSidebarListRef, Props>(
  function AppointmentsSidebarList({ items, onClick }, ref) {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const itemsRef = useRef<Array<HTMLDivElement | null>>([]);
    const sidebarRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const [isSticky, setIsSticky] = useState(false);
    const [selectedKey, setSelectedKey] = useState<string | undefined>();
    const isProcessingClick = useRef(false);

    // Derived values
    const years = useMemo(() => [...new Set(items.map((i) => getYear(i.date)))], [items]);
    const shouldShowYear = useMemo(() => years.length > 1, [years]);

    // Scroll the selected item into view if needed
    const scrollItemIntoView = useCallback(
      (key: string) => {
        const index = items.findIndex((i) => i.key === key);
        if (index === -1 || !itemsRef.current[index] || !sidebarRef.current) return;

        const item = itemsRef.current[index];
        if (!item) return;

        const sidebar = sidebarRef.current;
        const itemRect = item.getBoundingClientRect();
        const sidebarRect = sidebar.getBoundingClientRect();

        // Check if item is outside the visible area
        const isAbove = itemRect.top < sidebarRect.top;
        const isBelow = itemRect.bottom > sidebarRect.bottom;

        if (isAbove || isBelow) {
          item.scrollIntoView({
            block: isAbove ? 'start' : 'end',
            behavior: 'auto',
          });
        }
      },
      [items]
    );

    // Setup intersection observer for sticky detection
    useEffect(() => {
      if (!containerRef.current) return;

      const observer = new IntersectionObserver(([entry]) => setIsSticky(!entry.isIntersecting), {
        threshold: 0,
      });

      const sentinel = document.createElement('div');
      sentinel.style.cssText =
        'position:absolute;top:0;left:0;width:1px;height:1px;pointer-events:none;opacity:0;';

      containerRef.current.appendChild(sentinel);
      observer.observe(sentinel);

      return () => {
        observer.disconnect();
        sentinel.remove();
      };
    }, []);

    // Handle wheel events and ensure selected item is visible
    useEffect(() => {
      if (!sidebarRef.current) return;

      // Ensure selected item is visible when selection changes
      if (selectedKey && isSticky) {
        scrollItemIntoView(selectedKey);
      }

      // Handle wheel events for custom scrolling when sticky
      const sidebar = sidebarRef.current;
      const handleWheel = (e: WheelEvent) => {
        if (!isSticky) return;
        e.preventDefault();
        sidebar.scrollTop += e.deltaY;
      };

      // Reset scroll position when transitioning from sticky to non-sticky
      if (!isSticky && sidebar.scrollTop > 0) {
        sidebar.scrollTop = 0;
      }

      sidebar.addEventListener('wheel', handleWheel, { passive: false });
      return () => sidebar.removeEventListener('wheel', handleWheel);
    }, [isSticky, selectedKey, scrollItemIntoView]);

    // Handle item selection
    const handleItemClick = useCallback(
      (sidebarItem: Appointments.SidebarList.Item) => {
        if (isProcessingClick.current) return;

        isProcessingClick.current = true;
        setSelectedKey(sidebarItem.key);
        onClick(sidebarItem);

        setTimeout(() => {
          isProcessingClick.current = false;
        }, 300);
      },
      [onClick]
    );

    // Expose methods to parent component
    useImperativeHandle(
      ref,
      () => ({
        setKey: (key: string) => {
          if (key === selectedKey) return;
          setSelectedKey(key);
          setTimeout(() => scrollItemIntoView(key), 50);
        },
      }),
      [selectedKey, scrollItemIntoView]
    );

    // Mobile view
    if (isMobile) {
      return (
        <div className='z-10 fixed left-0 bottom-0 flex flex-row items-center justify-start overflow-x-auto scrollbar-hidden bg-product-sand-100 w-full gap-3 p-4'>
          {items?.map((sidebarItem, index) => {
            const selected =
              selectedKey === undefined && index === 0 ? true : selectedKey === sidebarItem.key;
            return (
              <div key={sidebarItem.key} ref={(el) => (itemsRef.current[index] = el)}>
                <AppointmentsCardDateSidebar
                  date={sidebarItem.date}
                  onSelect={() => handleItemClick(sidebarItem)}
                  selected={selected}
                />
              </div>
            );
          })}
        </div>
      );
    }

    // Desktop view
    return (
      <div className='-order-1 md:order-1 block relative' ref={containerRef}>
        <motion.div
          ref={sidebarRef}
          variants={{
            hidden: { opacity: 0 },
            show: { opacity: 1 },
          }}
          initial='hidden'
          animate='show'
          className={cn(
            'sticky top-0 flex flex-row md:flex-col items-center justify-start gap-[6.23px] h-screen pb-12 pt-9',
            {
              'overflow-y-auto scrollbar-hidden': isSticky,
              'overflow-y-hidden -mt-9': !isSticky,
            }
          )}
          style={{
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
          }}
        >
          {items?.map((sidebarItem, index) => {
            const selected =
              selectedKey === undefined && index === 0 ? true : selectedKey === sidebarItem.key;
            const year = getYear(sidebarItem.date);
            const prevYear = index > 0 ? getYear(items[index - 1].date) : null;
            const showYearLabel = shouldShowYear && (index === 0 || year !== prevYear);

            return (
              <motion.div
                ref={(el) => (itemsRef.current[index] = el)}
                variants={{
                  hidden: { translateY: -10, opacity: 0 },
                  show: { translateY: 0, opacity: 1, transition: { delay: 0.05 * index } },
                }}
                key={sidebarItem.key}
                className='flex flex-col gap-[6.23px] items-center'
              >
                {showYearLabel && (
                  <p
                    className={cn(TextLayout.body3, 'text-gray-500', {
                      'mt-3': index !== 0,
                    })}
                  >
                    {year}
                  </p>
                )}
                <AppointmentsCardDateSidebar
                  date={sidebarItem.date}
                  onSelect={() => handleItemClick(sidebarItem)}
                  selected={selected}
                />
              </motion.div>
            );
          })}
        </motion.div>
      </div>
    );
  }
);

function getYear(date: string) {
  return DateTime.fromFormat(date, 'yyyy-MM-dd').toFormat('yyyy');
}
