import { BigButton } from '@/components/button';
import { useAuthContext, useContactDialogContext } from '@/contexts';
import { usePatients } from '@/hooks';
import { eventAnalytics } from '@/providers';
import { motion } from 'framer-motion';
import { InputTextarea } from 'primereact/inputtextarea';
import { useCallback, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useMediaQuery } from 'react-responsive';
import { DialogModalFormMultiSelect } from './dialogModalFormMultiSelect';
import { MedicationDialogModalFormType } from './medicationDialogModalFormSchema';
import { TRACK_EVENTS } from '@/providers/eventAnalytics/trackEvents';
import { Medications } from '@/@types';

type Props = {
  medications: string[];
  defaultSelected?: Medications.Medication['medication'][];
};

export function MedicationsDialogModalForm({ medications, defaultSelected }: Props) {
  const [inputHeight, setInputHeight] = useState('auto');

  const { registerContactCMO } = usePatients();
  const { patient } = useAuthContext();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const { confirmPage, setConfirmPage } = useContactDialogContext();

  const { control, formState, handleSubmit, setError, reset, setValue } =
    useFormContext<MedicationDialogModalFormType>();

  const handleInput = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      e.target.style.height = 'auto';
      e.target.style.height = `${e.target.scrollHeight}px`;

      setInputHeight(`${e.target.scrollHeight}px`);
      setValue('message', e.target.value);

      if (e.target.offsetParent) {
        const parent = e.target.offsetParent as HTMLElement;
        const scrollDown = parent.scrollHeight - parent.clientHeight;
        parent.scrollTop = scrollDown;
      }
    },
    [setValue]
  );

  const onSubmit = useCallback(
    async (form: MedicationDialogModalFormType) => {
      if (!patient?.id || !patient?.cmoEmail) return;

      try {
        await registerContactCMO({
          to: patient?.cmoEmail,
          title: form.topic,
          body: form.message,
          patient: {
            id: patient?.id,
            firstName: patient.firstName,
            lastName: patient.lastName,
          },
        });
        setValue('message', '');
        setInputHeight('auto');
        reset();
        setConfirmPage(true);
        eventAnalytics.track(TRACK_EVENTS.REQUEST_REFILL_BUTTON_SENT, {
          'parent page': 'medications',
          medications: form.topic,
          message: form.message,
        });
      } catch (error: any) {
        setError('message', {
          type: 'manual',
          message: error?.response?.data?.message || error.message,
        });
        return;
      }
    },
    [
      patient?.cmoEmail,
      patient?.firstName,
      patient?.id,
      patient?.lastName,
      registerContactCMO,
      reset,
      setConfirmPage,
      setError,
      setValue,
    ]
  );

  const handleOnItemsSelected = useCallback(
    (value: string[]) => {
      if (value.length === 0) {
        return 'Select a medication';
      }
      const topic = `Refill request for ${value?.join(' and ')}`;
      setValue('topic', topic);
    },
    [setValue]
  );

  return (
    <form
      className='font-medium relative'
      onSubmit={handleSubmit(onSubmit)}
      style={{ height: isMobile ? 'calc(100% - 6.5rem)' : 'calc(430px - 4rem)' }}
    >
      <Controller
        control={control}
        name='topic'
        rules={{ required: true }}
        render={({ fieldState }) => {
          return (
            <div className='gap-2 flex flex-col'>
              <label className='text-sm' htmlFor='topic'>
                Medication
              </label>
              <DialogModalFormMultiSelect
                options={medications}
                selectPrefixLabel='Refill request for'
                placeholder='Select the medications'
                onItemsSelect={handleOnItemsSelected}
                defaultSelected={defaultSelected}
              />
              {fieldState.error?.message && (
                <span className='p-error text-sm'>{fieldState.error.message}</span>
              )}
            </div>
          );
        }}
      />
      <Controller
        control={control}
        name='message'
        rules={{ required: false }}
        render={({ field, fieldState }) => (
          <div className='space-y-2 flex flex-col mt-8 mb-auto'>
            <label className='text-sm' htmlFor='message'>
              Message
            </label>
            <InputTextarea
              {...field}
              maxLength={400}
              className='bg-kelp-dark text-white border-none min-h-[100px] resize-none overflow-hidden'
              placeholder='Any other details? (optional)'
              style={{ height: inputHeight }}
              onInput={handleInput}
            />
            {fieldState.error?.message && (
              <span className='p-error text-sm'>{fieldState.error.message}</span>
            )}
          </div>
        )}
      />
      <motion.div
        style={{
          opacity: !formState.isValid || confirmPage ? 0 : 1,
          pointerEvents: formState.isValid ? 'auto' : 'none',
          transition: 'opacity 1.5s',
        }}
        transition={{ duration: 1.5 }}
        className='md:mt-24 md:sticky absolute bottom-0 md:bottom-4 w-full'
      >
        <BigButton
          disabled={formState.isSubmitting || formState.isLoading}
          variant='white'
          className='py-5 px-4'
        >
          {formState.isSubmitting || formState.isLoading ? (
            <i className='pi pi-spinner animate-spin text-kelp'></i>
          ) : (
            'Send to your care team'
          )}
        </BigButton>
      </motion.div>
    </form>
  );
}
