import { cn, Button, ButtonProps, TextLayout } from '@lib-atria/ui-toolkit';

type ButtonVariants = ButtonProps['variant'];

export type HomePageCardProps = {
  category: string;
  title?: string;
  list?: Array<string>;
  descriptions?: Array<string>;
  buttons: Array<{ variant?: ButtonVariants; label: string; onClick: VoidFunction }>;
};

export function HomePageCard({ category, title, list, descriptions, buttons }: HomePageCardProps) {
  return (
    <div className='pt-3 px-3 pb-5 rounded-md flex flex-col bg-white gap-5'>
      <div className='py-6 px-8 bg-sand-100 rounded-md flex flex-col flex-1 gap-14 lg:min-h-[220px]'>
        <div className='flex-1'>
          <p className={cn(TextLayout.body1, 'text-kelp')}>{category}</p>
        </div>
        <div className='flex flex-col gap-1'>
          {!!title && <p className={cn(TextLayout.callout2, 'text-neue-kelp-500')}>{title}</p>}
          {!!list?.length &&
            list.map((item, index) => (
              <p key={index} className={cn(TextLayout.callout3, 'text-neue-kelp-500')}>
                {item}
              </p>
            ))}
          {!!descriptions?.length && (
            <div className='w-full flex gap-4'>
              {descriptions?.map((description, index) => {
                const hasNext = index < descriptions.length - 1;
                return (
                  <>
                    <p className={cn(TextLayout.body1, 'text-gray-500')}>{description}</p>
                    {hasNext && <p className={cn(TextLayout.body1, 'text-gray-500')}>•</p>}
                  </>
                );
              })}
            </div>
          )}
        </div>
      </div>
      <div className='px-8 w-full gap-3 flex flex-col md:flex-row'>
        {buttons.map((button, index) => (
          <Button
            key={index}
            size='large'
            variant={button.variant || 'tertiary'}
            label={button.label}
            onClick={button.onClick}
            className='w-full'
          />
        ))}
      </div>
    </div>
  );
}
