import { DateTime } from 'luxon';
import { useEffect } from 'react';

import { ImagingResultsCard, PageTitle } from '@/components';
import { useApplicationContext, usePageContext } from '@/contexts';
import { DateHelper, StringHelper } from '@/helper';
import { eventAnalytics } from '@/providers';
import { TRACK_EVENTS } from '@/providers/eventAnalytics/trackEvents';

export function ImagingResultsPage() {
  const { setPageTitle } = usePageContext();
  const { imagingResults, getImagingResults } = useApplicationContext();

  useEffect(() => {
    setPageTitle('Results');
    eventAnalytics.track(TRACK_EVENTS.IMAGING_RESULTS_PAGE_VIEWED);
  }, [setPageTitle]);

  useEffect(() => {
    if (!imagingResults) {
      getImagingResults();
    }
  }, [getImagingResults, imagingResults]);

  return (
    <div className='w-full flex-1 flex flex-col gap-16 md:gap-[6.25rem]'>
      <PageTitle title='Imaging records' />
      <ImagingResultsCard
        results={
          imagingResults?.map((result) => ({
            id: result.id,
            description: StringHelper.firstLetterUppercase(result.documentDescription),
            provider: result.providerName,
            date: result.observationDateTime
              ? DateHelper.formatDateToDisplay(DateTime.fromISO(result.observationDateTime))
              : DateHelper.formatDateToDisplay(DateTime.fromISO(result.createdDateTime)),
            pacsLink: result.pacsLink,
          })) || []
        }
      />
    </div>
  );
}
