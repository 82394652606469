import { SCREENS } from '@/screens';
import { cn, TextLayout } from '@lib-atria/ui-toolkit';
import { useMediaQuery } from 'react-responsive';

type Props = {
  title?: string;
  children: React.ReactNode;
  className?: string;
};

export function TableCard({ title, children, className = '' }: Props) {
  const isSmallMobile = useMediaQuery({ query: SCREENS.MOBILE_AND_SMALL_TABLET });
  return (
    <div
      className={cn(
        'flex flex-row gap-2 bg-neue-stone-400 rounded-sm px-4 py-[12px] items-baseline',
        className
      )}
    >
      {title && (
        <p
          className={cn(TextLayout.callout3, 'text-neue-kelp-400 md:mx-4 min-w-[30%] w-[30%]', {
            'w-full pb-1': isSmallMobile,
          })}
        >
          {title}
        </p>
      )}
      <div className='flex-1 overflow-x-hidden'>{children}</div>
    </div>
  );
}
