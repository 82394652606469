import { AllDoctors, Doctor } from '@/@types';
import { api } from '@/providers';

async function findDoctor(id: string) {
  return api.get<Doctor>(`/api/v1/cms/doctors/${id}`);
}

async function findAllDoctors() {
  return api.get<AllDoctors>('/api/v1/cms/doctors');
}

async function findSpecialistsByPatient() {
  return api.get<AllDoctors>('/api/v1/cms/specialists');
}

async function findAllLocations() {
  return api.get<any>('/api/v1/cms/locations');
}

export const CmsService = {
  findDoctor,
  findAllDoctors,
  findSpecialistsByPatient,
  findAllLocations,
};
