import { useEffect, useState } from 'react';

import {
  Greetings,
  HomeCards,
  HomeContentCards,
  HomeContentLoading,
  LabTrendsCard,
} from '@/components';
import { env } from '@/constants';
import { useApplicationContext, useAuthContext, usePageContext } from '@/contexts';
import { eventAnalytics } from '@/providers';
import { TRACK_EVENTS } from '@/providers/eventAnalytics/trackEvents';

export function HomePage() {
  const { patient } = useAuthContext();
  const { dashboardSummary, findDashboardSummary } = useApplicationContext();
  const { setPageTitle } = usePageContext();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setPageTitle('Home');
  }, [setPageTitle]);

  useEffect(() => {
    async function init() {
      setIsLoading(true);
      await findDashboardSummary();
      setIsLoading(false);
    }
    if (!dashboardSummary) {
      init();
    }
  }, [dashboardSummary, findDashboardSummary]);

  useEffect(() => {
    eventAnalytics.track(TRACK_EVENTS.HOME_PAGE_VIEWED, { patientId: patient?.id });
  }, [patient?.id]);

  return (
    <>
      {env.APP_FEATURE_FLAGS.IS_TO_SHOW_NEW_HOME_PAGE ? (
        <HomeContentCards />
      ) : (
        <>
          <div className='py-[44px] lg:py-[78px]'>
            <Greetings patientName={patient?.firstNameUsed || patient?.firstName || ''} />
          </div>
          {isLoading ? (
            <HomeContentLoading />
          ) : (
            <div className='w-full flex flex-col gap-4'>
              <LabTrendsCard />
              <HomeCards summary={dashboardSummary} />
            </div>
          )}
        </>
      )}
    </>
  );
}
