import { AnimationHelper, Button, cn, TextLayout } from '@lib-atria/ui-toolkit';
import { AnimatePresence, motion } from 'framer-motion';
import { useMemo, useRef, useState } from 'react';
import { ImagingResultType, LabResults, LabResultType } from '../labResults';

type Props = {
  results: {
    params: LabResultType['params'] | ImagingResultType['params'];
    id: number;
    key: string;
    type: string;
  }[];
  handleOnLabResultClick: (id: number, type: string) => void;
};

export function ResultsList({ results, handleOnLabResultClick }: Props) {
  const [shouldViewAllAppointments, setShouldViewAllAppointments] = useState(false);
  const resultsContainerRef = useRef<HTMLDivElement | null>(null);

  const displayedResults = useMemo(() => {
    return shouldViewAllAppointments ? results : results.slice(0, 4);
  }, [results, shouldViewAllAppointments]);

  const onClickShowMore = () => {
    setShouldViewAllAppointments((prev) => !prev);
    resultsContainerRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div
      className='flex flex-col rounded-lg px-[20px] py-[24px] bg-product-sand-100 gap-2'
      ref={resultsContainerRef}
    >
      <h2 className={cn(TextLayout.callout3, 'text-product-forest-100')}>Results</h2>
      <motion.div className='flex flex-col md:grid md:gap-4 md:grid-cols-2 lg:grid-cols-3'>
        <AnimatePresence>
          {displayedResults.map(({ type, params, id, key }) => (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.9, ease: AnimationHelper.ease }}
              className='flex pt-[12px] md:pt-[8px] justify-start md:[&>button]:w-full'
              key={key}
            >
              <LabResults
                result={
                  {
                    params,
                    type,
                  } as ImagingResultType | LabResultType
                }
                onClick={() => handleOnLabResultClick(id, type)}
              />
            </motion.div>
          ))}
        </AnimatePresence>
      </motion.div>
      {results.length > 10 && (
        <div className='flex justify-center pt-2'>
          <Button
            variant='secondary'
            label={shouldViewAllAppointments ? 'Show less' : 'Show more'}
            size='large'
            onClick={onClickShowMore}
          />
        </div>
      )}
    </div>
  );
}
