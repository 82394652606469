import { CustomTableHeader, CustomTableItems } from '@/@types';
import { MemberPortal } from '@/@types/memberPortal';
import { useContactDialogContext } from '@/contexts';
import { DateHelper } from '@/helper';
import { eventAnalytics } from '@/providers';
import { TRACK_EVENTS } from '@/providers/eventAnalytics/trackEvents';
import { SCREENS } from '@/screens';
import { Button, cn, TextLayout } from '@lib-atria/ui-toolkit';
import DOMPurify from 'dompurify';
import { DateTime } from 'luxon';
import { useCallback, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { TableCard } from '../card/tableCard';
import { ScoreCardCell } from './scoreCardCell';
import { ScorecardChartRow } from './scorecardChartRow';
import { ScorecardCustomTable } from './scorecardCustomTable';
import { ScoreCardEmptyCell } from './scoreCardEmptyCell';
import { ScoreCardStatusCell } from './scoreCardStatusCell';

type Trend = MemberPortal.AtriaTargets.TrendItem[];

type Props = {
  title: string;
  description?: string;
  howToImprove?: string;
  trend?: Trend;
  unitGroups: string[][];
  isAtriaTarget?: boolean;
  observationNote?: string;
  hasNotes?: boolean;
  collapse: Function;
  status?: string;
  atriaOptimalRange?: string;
  referenceRangeLabel?: string;
};

export function ScorecardExpandedContent({
  description,
  title,
  howToImprove = 'For personalized recommendations, please consult your care team.',
  trend: historical,
  unitGroups,
  isAtriaTarget = false,
  observationNote,
  hasNotes,
  collapse,
  status,
  atriaOptimalRange,
  referenceRangeLabel,
}: Props) {
  const { setContactDialogVisibility, setContactDialogTopic, setAnalyticsFormSubmittedEvent } =
    useContactDialogContext();
  const isSmallMobile = useMediaQuery({ query: SCREENS.MOBILE_AND_SMALL_TABLET });

  const openAskQuestionModal = useCallback(() => {
    const topic = `Question about ${title}`;
    eventAnalytics.track(TRACK_EVENTS.ASK_A_QUESTION_BUTTON_OPENED, {
      'parent page': 'lab trends',
      topic,
    });
    setAnalyticsFormSubmittedEvent(TRACK_EVENTS.ASK_A_QUESTION_SENT);
    setContactDialogTopic(topic);
    setContactDialogVisibility(true);
  }, [setAnalyticsFormSubmittedEvent, setContactDialogTopic, setContactDialogVisibility, title]);

  const navigateToResult = useCallback((documentId: number) => {
    window.open(`/results/lab/${documentId}`, '_blank');
  }, []);

  const tableHeaders: CustomTableHeader[] = [
    {
      label: 'Date',
      key: 'date',
    },
    {
      label: 'Value',
      key: 'value',
    },
    {
      label: 'Original source',
      key: 'source',
    },
  ];

  const historicalMapping: CustomTableItems[] = useMemo(() => {
    if (!historical) return [];

    return historical.map((analyte: any) => ({
      date: DateHelper.formatDateToDisplay(DateTime.fromISO(analyte.date, { zone: 'utc' })),
      value: {
        value: analyte.value,
        units: analyte.units || '',
        range: analyte.referenceRange,
      },
      source: (
        <button
          className='font-medium text-xs lg:text-sm text-gray-600 underline underline-offset-[5px] hover:text-neue-kelp-600 gap-2'
          onClick={() => navigateToResult(analyte?.documentId)}
        >
          <span className='text-neue-kelp-400'>View</span>
        </button>
      ),
      status: analyte?.status,
    }));
  }, [historical, navigateToResult]);

  const groupedByUnits = useMemo(() => {
    return Object.values(
      (historical ?? [])?.reduce<Record<string, Trend>>(
        (acc, item) => {
          const unit = item.units || '';
          if (unitGroups.length > 0) {
            const unitGroup = unitGroups.find((group) => group.includes(unit));
            if (unitGroup) {
              if (!acc[unitGroup[0]]) {
                acc[unitGroup[0]] = [];
              }
              acc[unitGroup[0]].push(item);
              return acc;
            }
          }

          if (!acc[unit]) {
            acc[unit] = [];
          }

          acc[unit].push(item);

          return acc;
        },
        {} as Record<string, Trend>
      )
    );
  }, [historical, unitGroups]);

  const cleanObservationNote = useCallback((note: string) => {
    const cleanedNote = note.replace(/\n\./g, '\n');
    return DOMPurify.sanitize(cleanedNote, { USE_PROFILES: { html: true } });
  }, []);

  const renderAbout = () => {
    if (!description) return;
    return (
      <TableCard
        className={cn('max-lg:flex max-lg:flex-col', {
          'lg:grid-cols-[28%_1fr_22%]': isAtriaTarget,
        })}
        title='About'
      >
        <p className={cn(TextLayout.body2, 'text-product-gray-500 pb-2')}>{description}</p>
      </TableCard>
    );
  };

  const renderHowToImprove = () => {
    return (
      <TableCard
        className={cn('max-lg:flex max-lg:flex-col', {
          'lg:grid-cols-[28%_1fr_22%]': isAtriaTarget,
        })}
        title='How to improve'
      >
        <p className={cn(TextLayout.body2, 'text-product-gray-500 mb-4')}>{howToImprove}</p>
        <Button
          variant='secondary'
          onClick={openAskQuestionModal}
          size='small'
          label='Ask a question'
        />
      </TableCard>
    );
  };

  const renderNoteFromLab = () => {
    if (!hasNotes || !observationNote) return;
    return (
      <TableCard
        title='Note from lab'
        className={cn('max-lg:flex max-lg:flex-col max-lg:items-stretch', {
          'lg:grid-cols-[28%_1fr_22%]': isAtriaTarget,
        })}
      >
        <div className='max-lg:p-2 max-lg:bg-[#D5D1C8] py-2 flex flex-col gap-4 max-lg:gap-3 items-start'>
          <pre
            className={cn(
              TextLayout.body2,
              'text-off-black whitespace-pre font-inherit font-[10px] overflow-x-auto w-full atria-scrollbar atria-scrollbar-reverse pb-2'
            )}
            dangerouslySetInnerHTML={{ __html: cleanObservationNote(observationNote) }}
          />
          <Button
            variant='secondary'
            onClick={openAskQuestionModal}
            size='small'
            label='Ask a question'
          />
        </div>
      </TableCard>
    );
  };

  const renderHistory = () => {
    return (
      <TableCard
        className={cn(
          'max-lg:flex max-lg:flex-col',
          isAtriaTarget
            ? 'items-start lg:grid-cols-[28%_1fr]'
            : 'items-start lg:grid-cols-[35%_1fr]'
        )}
        title='History'
      >
        <div className='flex flex-col gap-3 md:gap-0'>
          {isSmallMobile && (
            <div className='flex justify-between'>
              <div className={cn(TextLayout.body2, 'text-neue-kelp-400')}>Current status</div>
              <ScoreCardStatusCell
                status={{ status }}
                statusKey='status'
                className='bg-product-sand-500 h-[24px]'
              />
            </div>
          )}

          {isSmallMobile &&
            (isAtriaTarget ? (
              <div className='flex justify-between'>
                <div className={cn(TextLayout.body2, 'text-neue-kelp-400')}>
                  Atria optimal range
                </div>
                {atriaOptimalRange ? (
                  <ScoreCardCell
                    label={atriaOptimalRange}
                    hasNotes={hasNotes}
                    className='rounded-full px-4 bg-product-sand-500'
                  />
                ) : (
                  <ScoreCardEmptyCell />
                )}
              </div>
            ) : (
              <div className='flex justify-between'>
                <div className={cn(TextLayout.body2, 'text-neue-kelp-400')}>Reference range</div>
                {referenceRangeLabel ? (
                  <ScoreCardCell
                    label={referenceRangeLabel}
                    hasNotes={hasNotes}
                    className='rounded-full px-4 bg-product-sand-500'
                  />
                ) : (
                  <ScoreCardEmptyCell />
                )}
              </div>
            ))}

          {groupedByUnits?.map((historic, idx) => {
            const hasReferenceRange = historic.some((i) => i.referenceRange);
            return (
              historic?.some((x) => !isNaN(Number(x.value))) && (
                <div key={idx}>
                  {groupedByUnits.length > 1 && (
                    <div className='mb-4'>
                      <span className='text-product-500'>
                        {`${historic?.length || 0} result${historic?.length === 1 ? '' : 's'}${historic?.[0]?.units ? ` in ${historic[0].units}` : ''}`}
                      </span>
                    </div>
                  )}
                  <div className='mb-6 lg:mb-4 [&>*:first-child]:h-auto'>
                    <ScorecardChartRow
                      trend={historic as any}
                      width={575}
                      height={hasReferenceRange ? 237 : 100}
                      unitGroups={unitGroups}
                      showLabels={true}
                      hideWhenEmpty={true}
                    />
                  </div>
                </div>
              )
            );
          })}
          <ScorecardCustomTable headers={tableHeaders} items={historicalMapping} />
        </div>
      </TableCard>
    );
  };

  const renderClose = () => {
    return (
      <TableCard className='px-3'>
        <Button
          variant='secondary'
          size='medium'
          onClick={() => collapse()}
          label='Close'
          className='w-full'
        />
      </TableCard>
    );
  };

  return (
    <div className='flex w-full flex-col rounded-[4px] pointer-events-auto bg-[#DCD8D04D] border border-neue-stone-500 p-[6px] gap-[6px]'>
      {isSmallMobile ? (
        <>
          {renderHistory()}
          {renderAbout()}
          {renderHowToImprove()}
          {renderNoteFromLab()}
          {renderClose()}
        </>
      ) : (
        <>
          {renderAbout()}
          {renderHowToImprove()}
          {renderNoteFromLab()}
          {renderHistory()}
        </>
      )}
    </div>
  );
}
