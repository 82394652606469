import {
  DialogModalFormAppointments,
  PageTitle,
  UpcomingAppointments,
  PastAppointments,
} from '@/components';
import {} from '@/components/pastAppointments/pastAppointments';
import { useContactDialogContext, usePageContext } from '@/contexts';
import { eventAnalytics } from '@/providers';
import { TRACK_EVENTS } from '@/providers/eventAnalytics/trackEvents';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { DinamicSwitchContent } from './switchContent/dinamicSwitchContent';
import { ApppointmentTabTypes, ApppointmentTabTypesEnum } from '@/@types';

export function AppointmentsPage() {
  const location = useLocation();
  const { setPageTitle, setActions } = usePageContext();
  const [selectedTab, setSelectedTab] = useState<ApppointmentTabTypes>('upcoming');
  const selectedTabQueryString = useMemo(() => {
    const tab = new URLSearchParams(location.search).get('tab') as any;
    if ([ApppointmentTabTypesEnum.PAST, ApppointmentTabTypesEnum.UPCOMING].includes(tab)) {
      return tab;
    }
    return 'upcoming';
  }, [location.search]);

  const {
    setContent,
    setContactDialogTitle,
    resetContactDialog,
    setContactDialogVisibility,
    setContactDialogTopic,
  } = useContactDialogContext();

  const handleOpenContactCareButton = useCallback(
    (visible: boolean) => {
      setContactDialogVisibility(visible);
      setContactDialogTitle('Request appointment');
      setContent(<DialogModalFormAppointments />);
      eventAnalytics.track(TRACK_EVENTS.REQUEST_APPOINTMENT_BUTTON_CLICKED);
    },
    [setContactDialogVisibility, setContactDialogTitle, setContent]
  );

  useEffect(() => {
    setPageTitle('Appointments');

    setActions({
      buttons: [
        {
          icon: 'add',
          label: 'Schedule an appointment',
          onClick: () => handleOpenContactCareButton(true),
          variant: 'tertiary',
        },
      ],
    });
    return () => {
      resetContactDialog();
      setActions({ buttons: [] });
    };
  }, [
    handleOpenContactCareButton,
    resetContactDialog,
    setActions,
    setContactDialogTopic,
    setPageTitle,
  ]);

  useEffect(() => {
    setContactDialogTopic('General question about appointments');
  }, [setContactDialogTopic, setContent]);

  const handleSelectedOption = useCallback((option: string) => {
    eventAnalytics.track(TRACK_EVENTS.APPOINTMENTS_PAGE_NAV_CLICKED, {
      buttonClicked: option,
    });
    setSelectedTab(option as ApppointmentTabTypes);
  }, []);

  useEffect(() => {
    if (selectedTabQueryString) {
      eventAnalytics.track(TRACK_EVENTS.APPOINTMENTS_PAGE_VIEWED, {
        tabViewed: selectedTabQueryString,
      });
      setSelectedTab(selectedTabQueryString);
    } else {
      eventAnalytics.track(TRACK_EVENTS.APPOINTMENTS_PAGE_VIEWED);
    }
  }, [selectedTabQueryString]);

  return (
    <>
      <PageTitle title='Your Atria journey' />
      <DinamicSwitchContent
        onSelect={handleSelectedOption}
        options={[
          {
            label: 'Upcoming',
            value: ApppointmentTabTypesEnum.UPCOMING,
            component: UpcomingAppointments,
          },
          {
            label: 'Past',
            value: ApppointmentTabTypesEnum.PAST,
            component: PastAppointments,
          },
        ]}
        selectedOption={selectedTab as string}
      />
    </>
  );
}
