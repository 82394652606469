import { Disclaimer, PageTitle } from '@/components';
import { AppointmentsCard } from '@/components/appointmentsCard';
import { DialogModalFormAppointments } from '@/components/modal/dialogModal/dialoModalFormAppointments';
import { useApplicationContext, useContactDialogContext, usePageContext } from '@/contexts';
import { DateHelper } from '@/helper';
import { eventAnalytics } from '@/providers';
import { TRACK_EVENTS } from '@/providers/eventAnalytics/trackEvents';
import { DateTime } from 'luxon';
import { useCallback, useEffect } from 'react';

export function UpcomingAppointmentsPage() {
  const { setPageTitle, setActions, setContactButtonVisibility } = usePageContext();
  const { upcomingAppointments, getUpcomingAppointments } = useApplicationContext();
  const {
    setButtonTitle,
    setContent,
    setContactDialogTitle,
    resetContactDialog,
    setContactDialogVisibility,
  } = useContactDialogContext();

  useEffect(() => {
    getUpcomingAppointments();
  }, [getUpcomingAppointments]);

  const handleOpenContactCareButton = useCallback(() => {
    setButtonTitle('Request appointment');
    setContactDialogTitle('Request appointment');
    setContent(<DialogModalFormAppointments />);
    eventAnalytics.track(TRACK_EVENTS.REQUEST_APPOINTMENT_BUTTON_CLICKED, {
      'parent page': 'upcoming appointments',
    });
    setContactDialogVisibility(true);
  }, [setButtonTitle, setContactDialogVisibility, setContent, setContactDialogTitle]);

  useEffect(() => {
    return () => {
      setContactButtonVisibility(true);
      resetContactDialog();
    };
  }, [
    resetContactDialog,
    setButtonTitle,
    setContactButtonVisibility,
    setContactDialogTitle,
    setContent,
  ]);
  useEffect(() => {
    setPageTitle('Upcoming');
    setActions({
      buttons: [
        {
          icon: 'add',
          label: 'Request appointment',
          onClick: () => handleOpenContactCareButton(),
          variant: 'tertiary',
        },
      ],
    });
    setContactButtonVisibility(false);
    eventAnalytics.track(TRACK_EVENTS.UPCOMING_APPOINTMENTS_PAGE_VIEWED);
    return () => {
      setActions({ buttons: [] });
      setContactButtonVisibility(true);
    };
  }, [
    handleOpenContactCareButton,
    setActions,
    setContactButtonVisibility,
    setContactDialogVisibility,
    setPageTitle,
  ]);
  if (upcomingAppointments?.length === 0) {
    return (
      <div className='w-full flex-1 flex flex-col gap-16 md:gap-[6.25rem]'>
        <PageTitle
          title={
            <>
              Your upcoming <br /> appointments
            </>
          }
        />
        <Disclaimer
          text={
            <p>
              If you would like to schedule an appointment, please{' '}
              <span className='underline cursor-pointer' onClick={handleOpenContactCareButton}>
                contact your care team.
              </span>{' '}
              We are happy to assist you.
            </p>
          }
        />
      </div>
    );
  }

  return (
    <div className='w-full flex-1 flex flex-col gap-16 md:gap-[6.25rem]'>
      <PageTitle
        title={
          <>
            Your upcoming <br /> appointments
          </>
        }
      />
      <AppointmentsCard
        appointments={upcomingAppointments?.map((appointment) => ({
          id: appointment.appointmentId,
          appointmentId: appointment.appointmentId,
          start: DateHelper.formatDateToDisplay(DateTime.fromISO(appointment.date)),
          time: DateTime.fromISO(appointment.date).toFormat('hh:mm a'),
          providerName: appointment.providerName,
          title: appointment.type,
          hasEncounter: appointment.encounterState
            ? appointment.encounterState === 'CLOSED'
            : false,
          encounterId: appointment.encounterId,
          hasLetter: appointment.hasLetter,
        }))}
      />
    </div>
  );
}
