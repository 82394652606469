import { cn } from '@lib-atria/ui-toolkit';

import { SidebarVariants, StaggerChild, TextLayout } from '@/components';

type Props = {
  variant?: SidebarVariants;
  label: string;
  onClick: VoidFunction;
};

export function SidebarMobileListSubItem({ variant, label, onClick }: Props) {
  return (
    <StaggerChild>
      <a
        id={`${label?.toLowerCase()?.replace(/ /g, '')}Button`}
        href='#'
        onClick={onClick}
        className={cn(
          TextLayout.body1,
          'w-full flex items-center justify-center font-medium  leading-6 -tracking-[0.16px]',
          variant === 'forest' && 'text-white',
          variant === 'gray' && 'text-product-forest-100'
        )}
      >
        {label}
      </a>
    </StaggerChild>
  );
}
