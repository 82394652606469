import { DateTime } from 'luxon';
import { useEffect } from 'react';

import { LabResultsCard, PageTitle } from '@/components';
import { useApplicationContext, usePageContext } from '@/contexts';
import { DateHelper, StringHelper } from '@/helper';
import { eventAnalytics } from '@/providers';
import { TRACK_EVENTS } from '@/providers/eventAnalytics/trackEvents';

export function LabResultsPage() {
  const { setPageTitle, setIsBackButtonVisible } = usePageContext();
  const { labResults, getLabResults } = useApplicationContext();

  useEffect(() => {
    setPageTitle('Results');
    setIsBackButtonVisible(false);
    eventAnalytics.track(TRACK_EVENTS.LAB_RESULTS_PAGE_VIEWED);
  }, [setIsBackButtonVisible, setPageTitle]);

  useEffect(() => {
    if (!labResults) {
      getLabResults();
    }
  }, [getLabResults, labResults]);

  return (
    <div className='w-full flex-1 flex flex-col gap-16 md:gap-[6.25rem]'>
      <PageTitle title='Lab records' />
      <LabResultsCard
        results={
          labResults?.map((result) => ({
            id: result.id,
            description: StringHelper.firstLetterUppercase(result.description),
            provider: result.providerName,
            observationLength: result.observationLength || 1,
            hasAttachment: result.hasAttachment,
            date: result.observationDateTime
              ? DateHelper.formatDateToDisplay(DateTime.fromISO(result.observationDateTime))
              : DateHelper.formatDateToDisplay(DateTime.fromISO(result.createdDateTime)),
          })) || []
        }
      />
    </div>
  );
}
