import { Button } from '@lib-atria/ui-toolkit';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Patient } from '@/@types';
import { SearchPatientByName } from '@/components';
import { useApplicationContext, useAuthContext, usePageContext } from '@/contexts';
import { useLocation, useNavigate } from 'react-router-dom';
import { usePatients } from '@/hooks';

export function AccessAsAPatientPage() {
  const [patientData, setPatientData] = useState<Patient.Type>();

  const { setPageTitle } = usePageContext();
  const { signInAdmin } = useAuthContext();
  const { reset } = useApplicationContext();
  const { findPatientById } = usePatients();
  const location = useLocation();
  const navigate = useNavigate();

  const query = useMemo(() => new URLSearchParams(location.search), [location?.search]);
  const patientId = useMemo(() => query.get('patientId'), [query]);
  const redirectTo = useMemo(() => query.get('redirectTo'), [query]);

  useEffect(() => {
    setPageTitle('Access as a patient');
  }, [setPageTitle]);

  useEffect(() => {
    reset();
  }, [reset]);

  useEffect(() => {
    async function loadPatientByQueryString() {
      if (!patientId) {
        return;
      }
      const patient = await findPatientById({ patientId: Number(patientId) });
      if (!patient) {
        return;
      }
      signInAdmin(
        {
          id: patient.id,
          firstName: patient.firstName,
          lastName: patient.lastName,
          dateOfBirth: patient.dateOfBirth,
          patientName: patient.patientName,
          primaryProvider: {
            ...patient.primaryProvider,
            lastName: patient?.primaryProvider?.lastName || '',
          },
          email: patient.email,
          firstNameUsed: patient.firstName,
        },
        !redirectTo
      );
      if (redirectTo) {
        return navigate(redirectTo);
      }
    }
    loadPatientByQueryString();
  }, [findPatientById, navigate, patientId, redirectTo, signInAdmin]);

  const onSubmit = useCallback(() => {
    if (!patientData) return;
    signInAdmin({
      id: patientData.id,
      firstName: patientData.firstName,
      lastName: patientData.lastName,
      dateOfBirth: patientData.dateOfBirth,
      patientName: patientData.patientName,
      primaryProvider: {
        ...patientData.primaryProvider,
        lastName: patientData?.primaryProvider?.lastName || '',
      },
      email: patientData.email,
      firstNameUsed: patientData.firstName,
    });
  }, [patientData, signInAdmin]);

  return (
    <div className='w-full max-w-xl flex flex-col items-stretch justify-center gap-4'>
      <SearchPatientByName searchBy='lastName' onSelect={setPatientData} />
      <Button
        type='submit'
        variant='primary'
        size='large'
        label='Continue'
        onClick={onSubmit}
        disabled={!patientData}
      />
    </div>
  );
}
