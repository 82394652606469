import { PageTitle } from '@/components';
import { ScoreCardSkeletonLoader } from '@/components/scorecard/scoreCardSkeleton';
import { ScorecardSummary } from '@/components/scorecard/scorecardSummary';
import { ScorecardTable } from '@/components/scorecard/scorecardTable';
import { TableIdentifier } from '@/components/scorecard/useScoreCardTable';
import { env } from '@/constants';
import { useContactDialogContext, usePageContext } from '@/contexts';
import { eventAnalytics } from '@/providers';
import { TRACK_EVENTS } from '@/providers/eventAnalytics/trackEvents';
import { AnimationHelper } from '@lib-atria/ui-toolkit';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { usePatientScoreCardPage } from './usePatientScoreCardPage';

export function PatientScorecardPage() {
  const { setPageTitle, setIsBackButtonVisible } = usePageContext();
  const { setContactDialogTopic, isContactDialogVisible } = useContactDialogContext();
  const { isLoading, allAnalytesCards, atriaTargetsCards } = usePatientScoreCardPage();
  const isDesktopOrLargeTablet = useMediaQuery({ query: '(min-width: 1024px)' });

  useEffect(() => {
    setPageTitle('Results');
    setIsBackButtonVisible(false);
    eventAnalytics.track(TRACK_EVENTS.LAB_TRENDS_PAGE_VIEWED);
  }, [setIsBackButtonVisible, setPageTitle]);

  useEffect(() => {
    if (isContactDialogVisible) {
      setContactDialogTopic('Lab trends');
    }
  }, [setContactDialogTopic, isContactDialogVisible]);

  const isNotEmpty = useMemo(
    () => allAnalytesCards?.length && atriaTargetsCards?.length,
    [allAnalytesCards?.length, atriaTargetsCards?.length]
  );

  return (
    <div className='w-full flex-1 flex flex-col'>
      <PageTitle title='Lab trends' className='mb-24' />
      <ScorecardSummary analytes={allAnalytesCards} />

      <AnimatePresence>
        {isLoading ? (
          <ScoreCardSkeletonLoader />
        ) : (
          isNotEmpty && (
            <motion.div
              initial={{ translateY: -20, opacity: 0 }}
              animate={{ translateY: 0, opacity: 1, transition: { duration: 0.6 } }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.6, ease: AnimationHelper.ease }}
              className='max-w-full flex flex-col'
            >
              {env.APP_FEATURE_FLAGS.IS_TO_SHOW_ATRIA_TARGETS_TABLE && atriaTargetsCards && (
                <ScorecardTable
                  identifier={TableIdentifier.AtriaBiomarkers}
                  filterPosition='horizontal'
                  title='Atria key biomarkers'
                  description='These are some of the most important values to  monitor for healthspan and lifespan.'
                  cards={atriaTargetsCards}
                />
              )}
              {allAnalytesCards && (
                <ScorecardTable
                  identifier={TableIdentifier.AllBiomarkers}
                  filterPosition={isDesktopOrLargeTablet ? 'vertical' : 'horizontal'}
                  title='All biomarkers'
                  description='These are all the values your doctors are monitoring.'
                  cards={allAnalytesCards}
                />
              )}
            </motion.div>
          )
        )}
      </AnimatePresence>
    </div>
  );
}
