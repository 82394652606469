import { MemberPortal } from '@/@types';
import { DateHelper } from '@/helper';
import { eventAnalytics } from '@/providers';
import { TRACK_EVENTS } from '@/providers/eventAnalytics/trackEvents';
import { SCREENS } from '@/screens';
import {
  cn,
  CollapseTable,
  CollapseTableFilter,
  CollapseTableItem,
  CollapseTableProvider,
  TextLayout,
} from '@lib-atria/ui-toolkit';
import { useCallback, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useDebounceCallback } from 'usehooks-ts';
import { TableIdentifier, useScoreCardTable } from './useScoreCardTable';

type Props = {
  identifier: TableIdentifier;
  filterPosition?: 'horizontal' | 'vertical';
  title?: string;
  description?: string;
  cards: MemberPortal.FindAllBiomarkers.AllAnalytesTableList;
};

type SearchFilter = (item: CollapseTableItem, value?: string | null) => boolean;

export function ScorecardTable({
  identifier,
  filterPosition = 'horizontal',
  cards,
  title,
  description,
}: Props) {
  const { getTableFilters, filterGroups, getHeaders, separators } = useScoreCardTable({ cards });
  const [itemsFiltered, setItemsFiltered] = useState<CollapseTableItem[][]>([]);
  const isSmallMobile = useMediaQuery({ query: SCREENS.MOBILE_AND_SMALL_TABLET });

  const allItems = useMemo(
    () => cards.flatMap((c) => c.items.map((item) => [item, item.historical])),
    [cards]
  );

  const handleOnItemsFilter = useCallback((itemsList: CollapseTableItem[][]) => {
    setItemsFiltered(itemsList);
  }, []);

  const handleOnSearchFilterChange = useDebounceCallback((searchText: string) => {
    eventAnalytics.track(
      identifier === TableIdentifier.AllBiomarkers
        ? 'All biomarkers search input'
        : 'Atria key biomarkers search input',
      { input: searchText }
    );
  }, 2000);

  const searchFilter: SearchFilter = (item, value) => {
    if (!value) return true;
    handleOnSearchFilterChange(value);
    return item?.metric?.toLowerCase()?.includes(value.toLowerCase());
  };

  const filterClassName = useMemo(
    () =>
      filterPosition === 'vertical' ? 'overflow-x-visible w-fit max-w-[180px] sticky top-0' : '',
    [filterPosition]
  );

  const handleOnCollapse = useCallback((params?: { metric: string }) => {
    eventAnalytics.track(TRACK_EVENTS.BIOMARKER_DESCRIPTION_OPENED, {
      biomarkerName: params?.metric,
    });
  }, []);

  const handleOnSeeAllClicked = useCallback((params?: { title: string }) => {
    eventAnalytics.track(TRACK_EVENTS.ALL_BIOMARKER_SEE_ALL_BUTTON_CLICKED, {
      categoryName: params?.title,
    });
  }, []);

  const handleOnScroll = useCallback(() => {
    eventAnalytics.track(TRACK_EVENTS.HORIZONTAL_SCROLL_BUTTON_CLICKED);
  }, []);

  return (
    <CollapseTableProvider
      items={allItems}
      filters={getTableFilters(identifier) as any[]}
      filterGroups={filterGroups}
      searchFilter={searchFilter}
      onFilteredItemsChange={handleOnItemsFilter}
    >
      <div className='w-full max-w-full mt-10'>
        {title && (
          <div
            className='flex flex-col items-start justify-between my-7 pt-7 border-t border-kelp'
            data-tag={identifier === TableIdentifier.AllBiomarkers ? 'All' : 'Atria targets'}
          >
            <div className='flex-1'>
              <h1
                className={cn(TextLayout.h1.scto, 'text-kelp w-full lg:max-w-[50%] pb-4 lg:pb-0')}
              >
                {title}
              </h1>
            </div>
            {description && (
              <div className='flex-1 w-full items-end justify-start lg:justify-end flex flex-row'>
                <p className={cn(TextLayout.callout3, 'lg:max-w-[40%] text-neue-kelp-400/60')}>
                  {description}
                </p>
              </div>
            )}
          </div>
        )}
        <div
          className={cn(
            'items-start w-full grid',
            filterPosition === 'vertical'
              ? 'flex lg:flex-row flex-col h-full'
              : 'gap-4  grid-cols-1 pt-1 lg:pt-0'
          )}
        >
          <div
            className={cn(
              'flex flex-row items-center w-full',
              identifier === TableIdentifier.AllBiomarkers ? 'lg:w-[165px]' : 'lg:w-full'
            )}
          >
            <CollapseTableFilter
              disposition={filterPosition}
              icon='tune'
              className={filterClassName}
              searchInputMaxWidth={
                isSmallMobile ? 150 : identifier === TableIdentifier.AllBiomarkers ? 155 : 200
              }
            />
          </div>

          <div
            className={cn(
              'flex flex-col gap-[10px] w-full',
              identifier === TableIdentifier.AllBiomarkers ? 'lg:w-[calc(100%-165px)]' : 'lg:w-full'
            )}
          >
            {itemsFiltered.length === 0 && identifier === TableIdentifier.AllBiomarkers && (
              <div>
                <CollapseTable
                  title={'All'}
                  items={[]}
                  separators={separators}
                  headers={[]}
                  alwaysDisplayHeader
                />
              </div>
            )}
            {cards
              .sort((a, b) =>
                a.title === 'Other' ? 1 : b.title === 'Other' ? -1 : a.title.localeCompare(b.title)
              )
              .map((card) => {
                let pageSize = 5;
                const list = (itemsFiltered || []).filter((i) => {
                  return i.some((item) => item.__tag__ === card.tag);
                });
                if (list.length === 0 && card.tag !== 'atriaTargets') {
                  return null;
                }
                if (card.tag == 'atriaTargets') {
                  pageSize = list.length;
                  list.sort(
                    (a, b) =>
                      a[0].category.localeCompare(b[0].category) ||
                      a[0].metric.localeCompare(b[0].metric)
                  );
                }
                card.headers[1].sort((a, b) =>
                  b.date && a.date
                    ? DateHelper.getDate(b.date).getTime() - DateHelper.getDate(a.date).getTime()
                    : Number.NEGATIVE_INFINITY
                );
                return (
                  <div key={card.tag} data-tag={card.tag}>
                    <CollapseTable
                      onScroll={handleOnScroll}
                      title={card.tag == 'atriaTargets' ? '' : card.title}
                      pageSize={pageSize}
                      items={list}
                      separators={separators}
                      headers={getHeaders(card.headers)}
                      expandableConfig={{ key: 'expandableContent', contentIndex: 0 }}
                      onCollapse={handleOnCollapse}
                      onSeeAllClicked={handleOnSeeAllClicked}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </CollapseTableProvider>
  );
}
