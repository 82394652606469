import { TextLayout } from '@/components';
import { cn } from '@lib-atria/ui-toolkit';
import { useMemo } from 'react';

type Props = {
  quantity: number;
  date: string;
  doctor: string;
  notes: number;
};

export function LabResultContent({ doctor, notes, quantity, date }: Props) {
  const dateFormatted = useMemo(() => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  }, [date]);
  return (
    <>
      <div className='col-span-2' />
      <div className='col-span-2 flex flex-col justify-center'>
        <div className='line-clamp-5 flex w-full flex-col gap-3'>
          <p className={cn(TextLayout.body3, 'border-b border-white pb-1 text-white')}>
            {quantity || 0} result{quantity > 1 ? 's' : ''}
          </p>
          <p className={cn(TextLayout.body3, 'border-b border-white pb-1 text-white')}>
            {dateFormatted}
          </p>
          <p className={cn(TextLayout.body3, 'border-b border-white pb-1 text-white')}>{doctor}</p>
          {notes > 0 && (
            <p className={cn(TextLayout.body3, 'border-b border-white pb-1 text-white')}>
              {notes || 0} note{notes > 1 ? 's' : ''}
            </p>
          )}
        </div>
      </div>
    </>
  );
}
