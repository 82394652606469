import { cn } from '@lib-atria/ui-toolkit';

type Props = {
  status?: string;
  className?: string;
};

export function StatusPill({ status, className }: Props) {
  const statusLower = (status || '').toLowerCase();

  return status ? (
    <div
      className={cn(
        'flex rounded-full px-4 py-[2px] bg-product-sand-400 items-center gap-2',
        className
      )}
    >
      <div
        className={cn('flex rounded-full size-2 text-white', {
          'bg-rust': ['low', 'high', 'out of range'].includes(statusLower),
          'bg-neue-kelp-300': ['normal', 'optimal', 'highly optimal'].includes(statusLower),
        })}
      />
      <span className='text-neue-kelp-400 text-sm font-medium leading-4 -tracking-[0.35px] capitalize'>
        {status}
      </span>
    </div>
  ) : (
    <div className='flex py-[2px] items-center gap-2'>
      <div className='w-4 border-b-2 border-gray-400' />
    </div>
  );
}
