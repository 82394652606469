import { cn } from '@lib-atria/ui-toolkit';
import { TextLayout } from '../text';

type Props = {
  title: string;
  buttonLabel: string;
  onClick: VoidFunction;
};

export function CardNote({ title, buttonLabel, onClick }: Props) {
  return (
    <div className='flex h-[174px] md:h-[242px] w-full rounded-lg bg-neue-kelp-400 p-[10px] md:max-w-full'>
      <div className='relative flex h-full w-full flex-col items-start justify-end overflow-hidden rounded-[4px] bg-neue-kelp-600 p-[18px]'>
        <p className={cn(TextLayout.callout3, 'absolute bottom-10 text-white')}>{title}</p>
        <p
          className={cn(
            TextLayout.body2,
            'absolute bottom-5 cursor-pointer text-white opacity-60 hover:underline'
          )}
          onClick={onClick}
        >
          {buttonLabel}
        </p>
      </div>
    </div>
  );
}
