import { Checkbox, cn } from '@lib-atria/ui-toolkit';
import { Button } from 'primereact/button';
import {
  Fragment,
  HTMLAttributes,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  CustomTableFilters as CustomTableFiltersType,
  CustomTableHeader,
  CustomTableHeaderAction,
  CustomTableItems,
} from '@/@types';
import { StringHelper } from '@/helper';
import { useMediaQuery } from 'react-responsive';
import { TextLayout } from '../text';
import { CustomTableActionDialog } from './customTableActionDialog';
import { CustomTableCheckboxDialog, TableCheckboxAction } from './customTableCheckboxDialog';
import { CustomTableEmpty } from './customTableEmpty';
import { CustomTableExpandedContent } from './customTableExpandedContent';
import { CustomTableFilters } from './customTableFilters';
import { CustomTableHeaderActions } from './customTableHeaderActions';
import { CustomTableHeaderItem } from './customTableHeaderItem';
import { CustomTableItem } from './customTableItem';
import { CustomTableNote } from './customTableNote';

type CustomTableProps = HTMLAttributes<HTMLTableElement> & {
  sortable?: string[];
  headers: CustomTableHeader[];
  items: CustomTableItems[];
  headerActions?: CustomTableHeaderAction[];
  stickyActions?: boolean;
  emptyMessage?: string | ReactNode;
  pagination?: number;
  filters?: CustomTableFiltersType;
  selectable?: boolean;
  checkboxDialogOffset?: number;
  dialogActions?: {
    label: string;
    onClick: (list: any) => void;
  }[];
};

export function CustomTable(props: CustomTableProps) {
  return (
    <CustomTableActionDialog>
      <Component {...props} />
    </CustomTableActionDialog>
  );
}

function Component({
  sortable = [],
  headers,
  items,
  headerActions,
  emptyMessage,
  pagination,
  filters,
  selectable,
  dialogActions,
  checkboxDialogOffset = 0,
  stickyActions = false,
  ...rest
}: CustomTableProps) {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [displayItems, setDisplayItems] = useState<typeof items>([]);
  const [restItems, setRestItems] = useState<typeof items>([]);
  const [sortedItems, setSortedItems] = useState<typeof items>(items);
  const [currentPagination, setCurrentPagination] = useState(pagination);
  const [collapsedItems, setCollapsedItems] = useState<number[]>([]);
  const [activeFilters, setActiveFilters] = useState<CustomTableFiltersType>(filters || []);
  const [checkboxesState, setCheckboxesState] = useState<Map<number, boolean>>(new Map());
  const [checkboxActions, setCheckboxActions] = useState<TableCheckboxAction[]>([]);
  const [isCheckboxActionsVisible, setIsCheckboxActionsVisible] = useState(false);
  const [checkboxCheckedCount, setCheckboxCheckedCount] = useState(0);
  const [generalCheckboxIsChecked, setGeneralCheckboxIsChecked] = useState(false);

  const handleFilterChange = useCallback((updatedFilters: CustomTableFiltersType) => {
    setActiveFilters(updatedFilters);
  }, []);

  const hasHeaderActions = useMemo(
    () => items?.some((i) => i.actions) || (headerActions || [])?.length > 0,
    [headerActions, items]
  );

  const collapseItem = useCallback((key: number) => {
    setCollapsedItems((prev) => {
      const copy = [...prev];
      if (copy.includes(key)) {
        return copy.filter((i) => i !== key);
      }
      return [...copy, key];
    });
  }, []);

  const getItemsPaginated = useCallback(
    (list: any[]) => {
      const pagItems = [
        [...list.slice(0, currentPagination)],
        [...list.slice(currentPagination, list.length)],
      ];
      return pagItems;
    },
    [currentPagination]
  );

  const filterItems = useCallback(
    (filter: CustomTableItems[], itemsFilters: CustomTableFiltersType = []) => {
      if (itemsFilters.length === 0) return filter;

      return filter.filter((item) => {
        return itemsFilters.every((x) => {
          const key = String(x.key);
          return x.filterFunction && x.filterFunction(item[key], item);
        });
      });
    },
    []
  );

  const handleLoadMoreData = useCallback(() => {
    setCurrentPagination((actualValue) => actualValue! + pagination!);
  }, [pagination]);

  const handleOnHeaderItemSort = useCallback(
    (key: string, ordering: 'asc' | 'desc') => {
      const copy = [...items];
      copy.sort((a: any, b: any) => StringHelper.sort(a[key], b[key], ordering));
      setSortedItems(copy);
      const [currentDisplayItems, currentRestItems] = getItemsPaginated(copy);
      setDisplayItems(currentDisplayItems);
      setRestItems(currentRestItems);
    },
    [items, getItemsPaginated]
  );

  useEffect(() => {
    const filteredItems = filterItems(items, activeFilters);
    setSortedItems(filteredItems);

    const [currentDisplayItems, currentRestItems] = getItemsPaginated(filteredItems);
    setDisplayItems(currentDisplayItems);
    setRestItems(currentRestItems);
  }, [items, activeFilters, getItemsPaginated, filterItems]);

  useEffect(() => {
    const [currentDisplayItems, currentRestItems] = getItemsPaginated(sortedItems);

    if (selectable) {
      setCheckboxesState(new Map(currentDisplayItems.map((_, index) => [index, false])));
    }

    setDisplayItems(currentDisplayItems);
    setRestItems(currentRestItems);
  }, [sortedItems, getItemsPaginated, selectable]);

  useEffect(() => {
    setSortedItems(items);
  }, [items]);

  const onToggleDialog = useCallback(
    (checkboxStateMap: Map<number, boolean>, displayedItems?: unknown[]) => {
      if (!dialogActions) return;
      let currentDisplayItems: unknown[];
      if (displayedItems) {
        currentDisplayItems = displayedItems;
      } else {
        [currentDisplayItems] = getItemsPaginated(sortedItems);
      }

      const selectedIndexes = [...checkboxStateMap]
        .filter(([, isChecked]) => isChecked)
        .map(([index]) => index);

      setCheckboxCheckedCount(selectedIndexes.length);
      if (selectedIndexes.length == 0) {
        setIsCheckboxActionsVisible(false);
        return;
      }
      const selectedItems = selectedIndexes.map((index) => currentDisplayItems[index]);
      setCheckboxActions(
        dialogActions.map(({ label, onClick }) => {
          return { label, onClick: () => onClick(selectedItems) };
        })
      );

      setIsCheckboxActionsVisible(true);
    },
    [dialogActions, getItemsPaginated, sortedItems]
  );

  const onSetGeneralCheckbox = useCallback(
    (isChecked: boolean) => {
      const [currentDisplayItems] = getItemsPaginated(sortedItems);
      const checkboxStateMap = new Map(currentDisplayItems.map((_, index) => [index, isChecked]));
      setGeneralCheckboxIsChecked(isChecked);
      setCheckboxesState(checkboxStateMap);
      onToggleDialog(checkboxStateMap, currentDisplayItems);
    },
    [getItemsPaginated, onToggleDialog, sortedItems]
  );

  const onChangeAllCheckbox = useCallback(
    (element: React.ChangeEvent<HTMLInputElement>) => {
      onSetGeneralCheckbox(element.target.checked);
    },
    [onSetGeneralCheckbox]
  );

  const deselectAll = useCallback(() => {
    onSetGeneralCheckbox(false);
  }, [onSetGeneralCheckbox]);

  const onChangeItemSelection = useCallback(
    (element: React.ChangeEvent<HTMLInputElement> | boolean, key: number) => {
      checkboxesState.set(key, typeof element === 'boolean' ? element : element.target.checked);
      setCheckboxesState(checkboxesState);
      onToggleDialog(checkboxesState);
    },
    [checkboxesState, onToggleDialog]
  );

  return (
    <>
      {!!filters && <CustomTableFilters filters={filters} onFilterChange={handleFilterChange} />}

      <div className='w-full relative overflow-hidden rounded-xl'>
        <div className='overflow-x-auto rounded-xl'>
          <table
            {...rest}
            className={cn(rest.className, 'max-w-[100vw] w-full pr-10 rounded-xl md:mr-0', {
              'mb-[210px]': selectable && isCheckboxActionsVisible,
            })}
          >
            <thead className='bg-fern'>
              <tr className='first:rounded-xl last:rounded-xl'>
                {selectable && !isMobile && (
                  <th className='py-5 px-10'>
                    <div className='bg-product-sand-200 rounded-[4px]'>
                      <Checkbox
                        checked={generalCheckboxIsChecked}
                        onChange={onChangeAllCheckbox}
                        variant='tertiary'
                      />
                    </div>
                  </th>
                )}
                {headers.map((header: CustomTableHeader, index: number) => (
                  <CustomTableHeaderItem
                    key={header.key}
                    headers={headers}
                    headerActions={headerActions}
                    sortable={sortable}
                    index={index}
                    header={header}
                    onSort={handleOnHeaderItemSort}
                  />
                ))}
                {hasHeaderActions && (
                  <CustomTableHeaderActions
                    headerActions={headerActions}
                    isMobile={isMobile}
                    stickyActions={stickyActions}
                  />
                )}
              </tr>
            </thead>
            <tbody className='bg-white border-b-[1px] border-product-sand-200 rounded-xl'>
              {displayItems.length > 0 ? (
                displayItems.map((item, key) => {
                  const isCollapseVisible = collapsedItems.includes(key);
                  return (
                    <Fragment key={key}>
                      {item.isGroupHeader ? (
                        <tr
                          key={`group-${key}`}
                          className={cn(
                            'w-full px-8 text-left bg-white z-[1] relative border-b border-product-sand-200 duration-100'
                          )}
                        >
                          {selectable && !isMobile && (
                            <td className='py-5 px-10'>
                              <Checkbox
                                onChange={(element) => onChangeItemSelection(element, key)}
                                variant='tertiary'
                                checked={checkboxesState.get(key)}
                              />
                            </td>
                          )}
                          <td colSpan={headers.length + 1} className='pb-5 pt-12 px-10 text-left'>
                            <span className={cn(TextLayout.h3.ivar, 'text-fern')}>
                              {item.groupName}
                            </span>
                          </td>
                        </tr>
                      ) : (
                        <Fragment key={`${item.id ? item.id : `item-${key}`}`}>
                          <CustomTableItem
                            headers={headers}
                            item={item}
                            checked={checkboxesState.get(key)}
                            onChange={(element) => onChangeItemSelection(element, key)}
                            isCollapseVisible={isCollapseVisible}
                            displayItems={displayItems}
                            onCollapse={() => collapseItem(key)}
                            selectable={selectable}
                            isMobile={isMobile}
                            stickyActions={stickyActions}
                          />
                          <CustomTableExpandedContent
                            item={item}
                            itemKey={key}
                            isVisible={isCollapseVisible}
                            onCollapse={collapseItem}
                          />
                          <CustomTableNote note={item.note} />
                        </Fragment>
                      )}
                    </Fragment>
                  );
                })
              ) : (
                <CustomTableEmpty emptyMessage={emptyMessage} />
              )}
            </tbody>
          </table>
          {pagination && restItems.length > 0 && (
            <div className='flex items-center justify-center mt-3'>
              <Button
                label='Load more'
                onClick={handleLoadMoreData}
                size='small'
                icon='pi pi-chevron-down'
                iconPos='right'
                rounded
                outlined
              />
            </div>
          )}
          <CustomTableCheckboxDialog
            actions={checkboxActions}
            visible={isCheckboxActionsVisible}
            length={checkboxCheckedCount}
            deselectAll={deselectAll}
            offset={checkboxDialogOffset}
          />
        </div>
      </div>
    </>
  );
}
