import { cn } from '@lib-atria/ui-toolkit';

type Props = {
  id?: string;
  icon?: string;
  children?: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  className?: string;
  iconClassName?: string;
};

export function AtriaIconButton({ id, icon, onClick, children, className, iconClassName }: Props) {
  return (
    <button
      id={id}
      onClick={(e) => {
        e.stopPropagation();
        if (onClick) {
          onClick(e);
        }
      }}
      className={cn(
        'min-w-8 w-8 h-8 overflow-hidden rounded-full flex items-center justify-center transition-colors group',
        className
      )}
    >
      {!!icon && (
        <span
          className={cn(
            'material-icons-round font-normal text-base leading-4 text-center',
            iconClassName
          )}
        >
          {icon}
        </span>
      )}
      {!!children && children}
    </button>
  );
}
