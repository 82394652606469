import { MemberPortal } from '@/@types';
import { eventAnalytics } from '@/providers';
import { TRACK_EVENTS } from '@/providers/eventAnalytics/trackEvents';
import { SCREENS } from '@/screens';
import { CollapseTableItem } from '@lib-atria/ui-toolkit';
import { DateTime } from 'luxon';
import { useCallback, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { ScoreCardCell } from './scoreCardCell';
import { ScorecardChartRow } from './scorecardChartRow';
import { ScoreCardEmptyCell } from './scoreCardEmptyCell';
import { ScoreCardHistoricalCell } from './scoreCardHistoricalCell';
import { ScoreCardStatusCell } from './scoreCardStatusCell';
import { ScorecardTableCellBar } from './scorecardTableCellBar';
import { ScoreCardTableHeader } from './scoreCardTableHeader';

type CollapseTableHeaderItem<T = CollapseTableItem> = {
  key: string;
  label: string | React.ReactNode;
  width?: string;
  className?: string;
  renderItem?: (item: T, key: string) => React.ReactNode;
  freeze?: boolean;
  rowGroup?: boolean;
};

type Props = { cards: MemberPortal.FindAllBiomarkers.AllAnalytesTableList };
type Biomarker = MemberPortal.AtriaTargets.Biomarker;
type BiomarkerHistorical = Biomarker['historical'];
type Header = MemberPortal.AtriaTargets.HeaderItem;

export enum TableIdentifier {
  AtriaBiomarkers,
  AllBiomarkers,
}

export function useScoreCardTable({ cards }: Props) {
  const isSmallMobile = useMediaQuery({ query: SCREENS.MOBILE_AND_SMALL_TABLET });
  const separators = ['Historical findings'];

  const navigateToResult = useCallback((documentId: number) => {
    window.open(`/results/lab/${documentId}`, '_blank');
  }, []);

  const getTableFilters = useCallback(
    (tableId: TableIdentifier) => {
      const allBiomarkersCategoriesFilters: CollapseTableItem[] = cards
        .filter((card) => card.tag !== 'atriaTargets')
        .filter((card) => card.items.length > 0)
        .sort((a, b) => a.title.localeCompare(b.title))
        .map((card) => ({
          group: 2,
          type: 'select',
          label: card.title,
          key: card.tag,
          validateFn: (item: CollapseTableItem) => {
            return item.__tag__ === card.tag;
          },
          onClick: () => {
            const event =
              tableId === TableIdentifier.AtriaBiomarkers
                ? TRACK_EVENTS.ATRIA_KEY_BIOMARKERS_FILTER_CLICKED
                : TRACK_EVENTS.ALL_BIOMARKER_FILTER_CLICKED;
            eventAnalytics.track(event, { filter: card.tag });
          },
        }))
        .sort((a, b) => (a.label === 'Other' ? 1 : b.label === 'Other' ? -1 : 0));
      if (tableId === TableIdentifier.AllBiomarkers) {
        allBiomarkersCategoriesFilters.unshift({
          group: 2,
          type: 'select',
          label: 'All',
          key: 'all',
          defaultOption: true,
          validateFn: () => true,
          onClick: () =>
            eventAnalytics.track(TRACK_EVENTS.ALL_BIOMARKER_FILTER_CLICKED, { filter: 'All' }),
        });
      }

      return [
        {
          group: 1,
          type: 'select',
          label: 'All',
          key: 'all',
          validateFn: () => true,
          defaultOption: true,
          onClick: () => {
            const event =
              tableId === TableIdentifier.AtriaBiomarkers
                ? TRACK_EVENTS.ATRIA_KEY_BIOMARKERS_FILTER_CLICKED
                : TRACK_EVENTS.ALL_BIOMARKER_FILTER_CLICKED;
            eventAnalytics.track(event, { filter: 'All' });
          },
        },
        {
          group: 1,
          type: 'select',
          label: tableId === TableIdentifier.AtriaBiomarkers ? 'Optimal' : 'Optimal / Normal',
          validateFn: (item: CollapseTableItem) => {
            return (
              item?.status &&
              ['normal', 'optimal', 'highly optimal'].includes(item.status.toLowerCase())
            );
          },
          onClick: () => {
            const event =
              tableId === TableIdentifier.AtriaBiomarkers
                ? TRACK_EVENTS.ATRIA_KEY_BIOMARKERS_FILTER_CLICKED
                : TRACK_EVENTS.ALL_BIOMARKER_FILTER_CLICKED;
            const props = {
              filter: tableId === TableIdentifier.AtriaBiomarkers ? 'Optimal' : 'Optimal / Normal',
            };
            eventAnalytics.track(event, props);
          },
        },
        {
          group: 1,
          type: 'select',
          label: 'Out of range',
          validateFn: (item: CollapseTableItem) => {
            return (
              item?.status && ['low', 'high', 'out of range'].includes(item.status.toLowerCase())
            );
          },
          onClick: () => {
            const event =
              tableId === TableIdentifier.AtriaBiomarkers
                ? TRACK_EVENTS.ATRIA_KEY_BIOMARKERS_FILTER_CLICKED
                : TRACK_EVENTS.ALL_BIOMARKER_FILTER_CLICKED;
            eventAnalytics.track(event, { filter: 'Out of range' });
          },
        },
        {
          group: 1,
          type: 'text',
          label: 'Metric',
          key: 'metric',
          validateFn: (item: CollapseTableItem, value: string) => {
            if (!value) return true;
            return item?.metric && item.metric.toLowerCase().includes(value?.toLowerCase());
          },
        },
        ...allBiomarkersCategoriesFilters,
      ];
    },
    [cards]
  );

  const filterGroups = useMemo(
    () => [
      { group: 1, label: 'Filters' },
      {
        group: 2,
        label: 'Categories',
        hiddenOnMobile: true,
        mobileLabel: 'By category',
        singleSelectLabel: 'category',
        multiSelectLabel: 'categories',
        isMultiSelect: true,
      },
    ],
    []
  );

  const getAnalyteHeaders = useCallback(
    (header: CollapseTableHeaderItem[]): CollapseTableHeaderItem<Biomarker>[] => {
      return header?.map(({ key, label, ...rest }) => {
        if (key === 'metric') {
          return {
            key,
            label: 'Biomarker',
            className: 'md:w-[185px] w-[175px] justify-start',
            freeze: true,
            renderItem: (item: any) => {
              return (
                <div
                  lang='en'
                  className='h-full md:w-[185px] w-[175px] flex items-stretch gap-3 lg:gap-5 py-[10px] break-words hyphens-auto [hyphenate-limit-chars:10_4_4]'
                >
                  <ScorecardTableCellBar item={item} />
                  <div className='flex flex-col justify-center px-2 w-[calc(100%-6px-0.75rem)]'>
                    {item.metric}
                  </div>
                </div>
              );
            },
          };
        }
        if (key === 'result') {
          return {
            key,
            className: 'min-w-[103px]',
            label: 'Result',
            renderItem: (item) => {
              if (!item.result) {
                return <ScoreCardEmptyCell />;
              }
              return (
                <ScoreCardHistoricalCell
                  date={undefined}
                  value={item.result.value?.toString()}
                  units={item.result.units}
                  onClick={() => navigateToResult(item.historical[0]?.documentId)}
                />
              );
            },
          };
        }
        if (key === 'atriaOptimalRange') {
          return {
            key,
            label: (
              <ScoreCardTableHeader text='The Atria preventive range is the range we recommend for longevity.'>
                Atria optimal range
              </ScoreCardTableHeader>
            ),
            className: 'min-w-[145px]',
            renderItem: (item) => {
              if (!item.atriaOptimalRange) {
                return <ScoreCardEmptyCell />;
              }
              return <ScoreCardCell label={item.atriaOptimalRange} hasNotes={item.hasNotes} />;
            },
          };
        }
        if (key === 'date') {
          return {
            key,
            label,
            className: 'min-w-[89px]',
            renderItem: (item) => {
              if (!item.date) {
                return <ScoreCardEmptyCell />;
              }
              return (
                <div className='w-full justify-center flex items-center'>
                  <p className='text-[11px] font-medium leading-4 -tracking-[0.11px] text-gray-500'>
                    {DateTime.fromJSDate(new Date(item.date)).toFormat('MMM yyyy')}
                  </p>
                </div>
              );
            },
          };
        }
        if (key === 'status') {
          return {
            key,
            className: 'min-w-[131px]',
            label: (
              <ScoreCardTableHeader text='The status of your historical finding. Note that this status comes from the lab and may be related to a different reference range from the one you saw on the left.'>
                {label}
              </ScoreCardTableHeader>
            ),
            renderItem: (item, itemKey) => {
              return <ScoreCardStatusCell status={item} statusKey={itemKey} />;
            },
          };
        }
        if (key === 'referenceRange') {
          return {
            key,
            label,
            className: 'min-w-[130px] w-[130px]',
            onCollapse: (item: Biomarker) => {
              return item.hasNotes ?? false;
            },
            renderItem: (item) => {
              const historical = (item.historical || []).find((h) => h.referenceRangeLabel);
              const referenceRangeLabel = historical?.referenceRangeLabel;

              if (!referenceRangeLabel) {
                return <ScoreCardEmptyCell />;
              }
              return <ScoreCardCell label={referenceRangeLabel} hasNotes={item.hasNotes} />;
            },
          };
        }
        if (key === 'trend') {
          return {
            key,
            label: (
              <ScoreCardTableHeader text='This trend shows whether your most recent finding is higher or lower than the previous finding.'>
                Trend
              </ScoreCardTableHeader>
            ),
            className: 'min-w-[160px] [&>*:first-child]:justify-center',
            renderItem: (item: any) => {
              return (
                <div className='w-full flex items-center'>
                  <ScorecardChartRow trend={item.historical} unitGroups={item.unitGroups} />
                </div>
              );
            },
          };
        }
        return { key, label, ...rest };
      });
    },
    [navigateToResult]
  );

  const getHistoricalHeaders = useCallback(
    (headers: CollapseTableHeaderItem[]): CollapseTableHeaderItem<BiomarkerHistorical>[] => {
      return headers?.map(({ key, label }) => {
        return {
          key,
          label,
          className: 'min-w-[120px] w-[120px]',
          renderItem: (historical, itemKey: string) => {
            const validHistorical = historical.filter((item) => {
              const dateKey = DateTime.fromISO(item.date!)
                .toUTC()
                .toFormat('MMM_yyyy')
                ?.toLowerCase();
              return dateKey === itemKey;
            });

            if (validHistorical.length === 0) {
              return <ScoreCardEmptyCell />;
            }

            const list = validHistorical
              .sort((a, b) => {
                const aDate = DateTime.fromISO(a.date, { zone: 'utc' }).toMillis();
                const bDate = DateTime.fromISO(b.date, { zone: 'utc' }).toMillis();
                return bDate - aDate;
              })
              .map((item) => {
                const date = DateTime.fromISO(item.date, { zone: 'utc' }).toFormat('MMM dd');
                return (
                  <ScoreCardHistoricalCell
                    key={item.documentId}
                    date={validHistorical.length > 1 ? date : undefined}
                    value={item.value}
                    units={item.units}
                    onClick={() => navigateToResult(item.documentId)}
                  />
                );
              });

            return <div className='flex-col py-4'>{list}</div>;
          },
        };
      });
    },
    [navigateToResult]
  );

  const getMobileHeaders = useCallback(
    (headers: CollapseTableHeaderItem[]): CollapseTableHeaderItem<Biomarker>[] => {
      return headers
        ?.filter(({ key, rowGroup }) => {
          if (key === 'metric') return true;
          if (rowGroup) return true;
          return false;
        })
        .map(({ key, label, ...rest }) => {
          if (key === 'metric') {
            return {
              key,
              label,
              className: 'justify-start',
              freeze: true,
              renderItem: (item: any) => {
                return (
                  <div className='flex items-center py-[10px] whitespace-normal w-full h-full min-h-[120px] justify-between'>
                    <div className='w-[calc(100%-1rem-135px)] sm:w-[calc(100%-1rem-200px)] h-full flex gap-3 items-center'>
                      <ScorecardTableCellBar item={item} />
                      <div className='flex flex-col items-start'>
                        <div className='text-neue-kelp-400'>{item.metric}</div>
                        {!item.result ? (
                          <ScoreCardEmptyCell />
                        ) : (
                          <ScoreCardHistoricalCell
                            date={undefined}
                            value={item.result.value?.toString()}
                            units={item.result.units}
                            onClick={() => navigateToResult(item.historical[0]?.documentId)}
                            className='m-0 p-0'
                          />
                        )}
                      </div>
                    </div>
                    <div className='w-[135px] sm:w-[200px]'>
                      <ScorecardChartRow trend={item.historical} unitGroups={item.unitGroups} />
                    </div>
                  </div>
                );
              },
            };
          }
          return { key, label, ...rest };
        });
    },
    [navigateToResult]
  );

  const getHeaders = useCallback(
    (headersLists: Header[][]): any[][] => {
      if (!Array.isArray(headersLists) || headersLists.length === 0) return [];
      if (isSmallMobile) {
        return [getMobileHeaders(headersLists[0])];
      }

      return headersLists?.map((headerList, headerIndex) => {
        if (headerIndex === 0) {
          return getAnalyteHeaders(headerList);
        }
        return getHistoricalHeaders(headerList);
      });
    },
    [getAnalyteHeaders, getHistoricalHeaders, getMobileHeaders, isSmallMobile]
  );

  return {
    separators,
    getTableFilters,
    filterGroups,
    getHeaders,
  };
}
