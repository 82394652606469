import { StatusPill } from '../statusPill/statusPill';
import { ScoreCardEmptyCell } from './scoreCardEmptyCell';

type Props = {
  status: Record<string, any> | null;
  statusKey: string;
  className?: string;
};

export function ScoreCardStatusCell({ status, statusKey, className }: Props) {
  if (!status) {
    return <ScoreCardEmptyCell />;
  }
  const [value] = Object.values(status);

  if (!value || !status[statusKey] || status[statusKey].length === 0) {
    return <ScoreCardEmptyCell />;
  }

  const items: { status: string }[] = Array.isArray(value)
    ? value
    : [{ status: status[statusKey] }];

  return (
    <div className='flex flex-col items-center'>
      {items.map((item, index) => (
        <StatusPill key={index} status={item.status} className={className} />
      ))}
    </div>
  );
}
