import { LabResult } from '@/@types';
import { DateHelper, StringHelper } from '@/helper';
import { cn, TextLayout } from '@lib-atria/ui-toolkit';
import { DateTime } from 'luxon';
import { useMemo } from 'react';

type ResultPageHeaderProps = {
  result: LabResult;
};

export function ResultPageHeader({ result }: ResultPageHeaderProps) {
  const date = useMemo(() => {
    if (!result) return;
    return DateHelper.formatDateToDisplay(
      DateTime.fromISO(result.observationDateTime ?? result.createdDateTime)
    );
  }, [result]);

  return (
    <div className='flex flex-col gap-4 pb-6'>
      <div className='flex flex-col gap-2 mb-12 mt-8'>
        <h2 className={cn(TextLayout.h3.scto, 'text-kelp')}>
          {StringHelper.firstLetterUppercase(result.description)}
        </h2>
        <h3 className={cn(TextLayout.h3.scto, 'text-gray-500')}>Original results from lab</h3>
      </div>
      <div className='flex flex-col'>
        <div className='flex gap-2'>
          <span className={cn(TextLayout.body1, 'text-forest-100')}>Date:</span>
          <span className={cn(TextLayout.body1, 'text-gray-500')}>{date}</span>
        </div>
        {result.patientNote && (
          <div className='flex gap-2'>
            <span className={cn(TextLayout.body1, 'text-forest-100')}>Note:</span>
            <span className={cn(TextLayout.body1, 'text-gray-500')}>{result.patientNote}</span>
          </div>
        )}
      </div>
    </div>
  );
}
