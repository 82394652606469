import { DateHelper, InfiniteScroll } from '@lib-atria/ui-toolkit';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useRef } from 'react';

import { Appointments } from '@/@types';
import { useApplicationContext, useContactDialogContext } from '@/contexts';
import { TimerHelper } from '@/helper';
import { AppointmentsCardFooter, AppointmentsCardSchedule } from '../appointmentsCard';
import { AppointmentsSidebarList } from '../appointmentsSidebarList';
import { AtriaIcon } from '../icons';
import { DialogModalFormAppointments } from '../modal';
import { AppointmentsBlank } from '../pastAppointments/appointmentsBlank';
import { UpcomingSkeletonLoader } from './upcomingSkeletonLoader';
import { DateHelper as LocalDateHelper } from '@/helper/dateHelper';
import { DateModule } from '../dateModule';
import { eventAnalytics } from '@/providers';
import { TRACK_EVENTS } from '@/providers/eventAnalytics/trackEvents';

export function UpcomingAppointments() {
  const {
    upcomingAppointmentsSidebarList,
    hasMoreUpcomingAppointments,
    getUpcomingAppointmentsExtended,
  } = useApplicationContext();
  const {
    isContactDialogVisible,
    setContactDialogVisibility,
    setContent,
    setContactDialogTitle,
    setContactDialogTopic,
    setContactDialogNote,
  } = useContactDialogContext();
  const sectionRefs = useRef<(HTMLDivElement | null)[]>([]);
  const infiniteScrollRef = useRef<any>();
  const sidebarListRef = useRef<any>();
  const infiniteScrollItemRef = useRef<IntersectionObserver>(
    new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting) {
            sidebarListRef.current?.setKey(entry.target.getAttribute('id'));
          }
        }
      },
      { threshold: 0.3 }
    )
  );

  const handleScheduleAppointment = useCallback(() => {
    setContent(<DialogModalFormAppointments />);
    setContactDialogTitle('Request appointment');
    setContactDialogVisibility(!isContactDialogVisible);
    eventAnalytics.track(TRACK_EVENTS.REQUEST_APPOINTMENT_BUTTON_CLICKED);
  }, [isContactDialogVisible, setContactDialogTitle, setContactDialogVisibility, setContent]);

  const endDateTime = useCallback((endTime: string) => {
    const endDateObj = DateTime.fromISO(endTime);
    if (endDateObj.toJSDate().getMinutes() > 0) {
      return LocalDateHelper.formatTimeToUpperMeridian(endDateObj.toFormat('h:mma'));
    }
    return LocalDateHelper.formatTimeToUpperMeridian(endDateObj.toFormat('ha'));
  }, []);

  const startDateTime = useCallback((startTime: string) => {
    const startDateObj = DateTime.fromISO(startTime);
    if (startDateObj.toJSDate().getMinutes() > 0) {
      return LocalDateHelper.formatTimeToUpperMeridian(startDateObj.toFormat('h:mma'));
    }
    return LocalDateHelper.formatTimeToUpperMeridian(startDateObj.toFormat('ha'));
  }, []);

  const handleAskQuestion = useCallback(
    (dateSelected?: string, startDate?: string, endDate?: string) => {
      eventAnalytics.track(TRACK_EVENTS.REQUEST_APPOINTMENT_BUTTON_CLICKED);

      setContent(null);
      setContactDialogTitle('How can we help?');
      setContactDialogNote('');

      if (!dateSelected || !startDate || !endDate) {
        setContactDialogTopic('Question about my appointment');
        setContactDialogVisibility(!isContactDialogVisible);
        return;
      }
      const date = DateTime.fromISO(dateSelected);
      const formatted = LocalDateHelper.formatDateToDisplay(date).split(',')[0];
      setContactDialogTopic(
        `Question about ${formatted}, ${startDateTime(startDate)} - ${endDateTime(endDate)} appointment`
      );
      setContactDialogVisibility(!isContactDialogVisible);
    },
    [
      endDateTime,
      isContactDialogVisible,
      setContactDialogTopic,
      setContactDialogVisibility,
      startDateTime,
      setContactDialogNote,
      setContactDialogTitle,
      setContent,
    ]
  );

  const handleInfiniteScrollItemRef = useCallback((e: HTMLDivElement | null) => {
    if (e) {
      infiniteScrollItemRef.current.observe(e);
    }
  }, []);

  const handleSidebarItemClick = useCallback(async (sidebarItem: Appointments.SidebarList.Item) => {
    const { key, page } = sidebarItem;
    const element = document.getElementById(key);
    if (element) {
      infiniteScrollRef.current.selectPage(page);
      element?.scrollIntoView({ behavior: 'smooth' });
    } else {
      await infiniteScrollRef.current.loadMoreItems({ page });
      await TimerHelper.delay(300, () => {
        document.getElementById(key)?.scrollIntoView({ behavior: 'smooth' });
      });
    }
  }, []);

  useEffect(() => {
    const handler = TimerHelper.debounce(() => {
      infiniteScrollRef.current?.cleanUp();
    }, 500);
    const root = document.getElementById('root');
    root?.addEventListener('scrollend', handler);
    return () => root?.removeEventListener('scrollend', handler);
  }, []);

  return (
    <div className='w-full grid grid-cols-1 gap-5 md:gap-[79px] relative md:grid-cols-[1fr_93px]'>
      <div className='flex-1 w-full flex flex-col gap-0.5'>
        <InfiniteScroll
          method='REPLACE'
          ref={infiniteScrollRef}
          hasMore={hasMoreUpcomingAppointments}
          fetchData={getUpcomingAppointmentsExtended}
          LoadingComponent={<UpcomingSkeletonLoader />}
          NoMoreContentComponent={<></>}
          EmptyList={
            <AppointmentsBlank
              onClick={handleAskQuestion}
              text='You don’t have any upcoming appointments scheduled at this time.'
            />
          }
          renderItem={(groupItem: Appointments.FindAllPatientsAppointments.GroupItem, index) => {
            const locationType = groupItem.location === 'Telehealth' ? 'Video' : groupItem.location;
            const date = DateTime.fromISO(groupItem.date);
            const formatted = DateHelper.formatDateToDisplay(date).split(',')[0];
            const appointmentDate = `${formatted}, ${startDateTime(groupItem.startDate)} - ${endDateTime(groupItem.endDate)}`;

            return (
              <div key={groupItem.key} id={groupItem.key} ref={handleInfiniteScrollItemRef}>
                <div className='space-y-4 relative' ref={(el) => (sectionRefs.current[index] = el)}>
                  <DateModule
                    location={locationType}
                    date={groupItem.date}
                    endTime={groupItem.endDate}
                    startTime={groupItem.startDate}
                    modifyAppointment={() =>
                      handleAskQuestion(groupItem.date, groupItem.startDate, groupItem.endDate)
                    }
                  />

                  <AppointmentsCardSchedule
                    groupItem={groupItem}
                    doctor={groupItem.doctor}
                    lastModified={groupItem.lastModified}
                    appointmentDate={appointmentDate}
                  />
                </div>
                <AtriaIcon className='mx-0 w-full my-14 h-8 ' fill='#CBC6BD' />
              </div>
            );
          }}
        />

        {upcomingAppointmentsSidebarList.length > 0 && (
          <AppointmentsCardFooter
            text='Your care team is here to assist with your appointments. Please reach out at any time.'
            buttons={[
              { label: 'Schedule an appointment', onClick: handleScheduleAppointment },
              { label: 'Ask a question', onClick: handleAskQuestion },
            ]}
          />
        )}
      </div>
      <AppointmentsSidebarList
        items={upcomingAppointmentsSidebarList}
        ref={sidebarListRef}
        onClick={handleSidebarItemClick}
      />
    </div>
  );
}
