import { useCallback } from 'react';
import { LabResultContent } from './labResultContent';
import { cn, TextLayout } from '@lib-atria/ui-toolkit';

export type LabResultType = {
  type: 'Lab';
  params: {
    name: string;
    quantity: number;
    date: string;
    doctor: string;
    notes: number;
  };
};
export type ImagingResultType = {
  type: 'Imaging';
  params: {
    name: string;
    quantity: number;
    date: string;
    doctor: string;
    notes: number;
  };
};

export type LabResultsProps = {
  result: ImagingResultType | LabResultType;
  onClick: VoidFunction;
};

export function LabResults({ result, onClick }: LabResultsProps) {
  const handleOnClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
      onClick();
    },
    [onClick]
  );

  return (
    <div
      onClick={handleOnClick}
      aria-label={result.params.name}
      className='group flex h-[350px] w-full md:w-[231px] cursor-pointer flex-col items-stretch rounded-lg border-2 border-transparent bg-neue-kelp-400 p-4 text-white outline-none transition-all ease-global focus-within:border-2 focus-within:border-forest-100 hover:bg-neue-kelp-600 active:scale-95'
    >
      <div className='flex flex-col'>
        <div className='h-[0.5px] w-full bg-white' />
        <p className={cn(TextLayout.body3, 'text-left', 'pt-[8px] ')}>{result.type}</p>
      </div>
      <div className='grid h-full w-full'>
        <div className='h-[130spx] sm:h-[150px] flex items-end border-b border-white'>
          <p
            className={cn(
              TextLayout.callout3,
              'flex max-h-[110px] mb-2 !leading-7 overflow-hidden text-ellipsis line-clamp-3'
            )}
          >
            {result.params.name}
          </p>
        </div>

        <div className='grid w-full grid-cols-4'>
          <LabResultContent {...result.params} />
        </div>
      </div>
    </div>
  );
}
