import { cn } from '@lib-atria/ui-toolkit';

interface Props {
  item: any;
  className?: string;
}

export function ScorecardTableCellBar({ item, className }: Props) {
  const getBarColor = () => {
    const result = Number(item.result.value);
    const referenceRangeLabel = item.referenceRangeLabel?.text || item.referenceRangeLabel;
    const historicalItem = item.historical.find(
      (r: any) => r.referenceRangeLabel === referenceRangeLabel
    );

    if (item.status) {
      if (['low', 'high', 'out of range'].includes(item.status?.toLowerCase())) return 'bg-rust';
      if (['normal', 'optimal', 'highly optimal'].includes(item.status?.toLowerCase()))
        return 'bg-neue-kelp-200';
    }

    if (!historicalItem) {
      return 'bg-gray-400';
    }
    const { referenceRange } = historicalItem;
    if (!referenceRange) {
      return 'bg-gray-400';
    }
    if (
      (!referenceRange.min || referenceRange.min <= result) &&
      (!referenceRange.max || referenceRange.max >= result)
    ) {
      return 'bg-neue-kelp-300';
    }
    return 'bg-rust';
  };

  return <div className={cn('w-[6px] min-w-[6px] h-full rounded-md', getBarColor(), className)} />;
}
