import { cn, TextLayout } from '@lib-atria/ui-toolkit';
import { motion } from 'framer-motion';
import React, { useCallback, useEffect, useState } from 'react';

interface Props {
  count: number;
  height: number;
  text: string;
  onClick: React.MouseEventHandler;
}

export function ScorecardSummaryBar({ count, height, text, onClick }: Props) {
  const [isViewCursorVisible, setIsViewCursorVisible] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      setMousePosition({ x: event.pageX, y: event.pageY });
    };

    document.addEventListener('mousemove', handleMouseMove);
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  const onMouseEnterBar = useCallback(() => {
    setIsViewCursorVisible(true);
  }, []);

  const onMouseLeaveBar = useCallback(() => {
    setIsViewCursorVisible(false);
  }, []);

  return (
    <>
      <motion.div
        className={cn(
          'flex flex-col w-full max-w-[152px] bg-white justify-end items-center rounded-[4px] cursor-none',
          count !== 0 && 'min-h-[105px]'
        )}
        style={{
          height: count === 0 ? '7px' : `${height}px`,
        }}
        initial={{ height: '0px', opacity: 0 }}
        animate={{
          height: count === 0 ? '7px' : `${height}px`,
          opacity: 1,
        }}
        transition={{
          type: 'spring',
          stiffness: 100,
          damping: 20,
          duration: 0.8,
        }}
        onClick={onClick}
        onMouseEnter={onMouseEnterBar}
        onMouseLeave={onMouseLeaveBar}
      >
        <span className={cn(TextLayout.callout1, count === 0 && 'text-white')}>{count}</span>
        <span
          className={cn(
            TextLayout.body2,
            'text-sm text-product-gray-500 mb-6',
            count === 0 && 'text-white'
          )}
        >
          {text}
        </span>
      </motion.div>

      {isViewCursorVisible && (
        <motion.div
          initial={{ height: '0px' }}
          animate={{
            height: '60px',
          }}
          transition={{
            type: 'spring',
            stiffness: 100,
            damping: 20,
            duration: 0.8,
          }}
          className='fixed w-[60px] h-[60px] bg-neue-kelp-600 text-white rounded-full flex justify-center items-center pointer-events-none'
          style={{
            left: mousePosition.x,
            top: mousePosition.y,
          }}
        >
          View
        </motion.div>
      )}
    </>
  );
}
