import mixpanel from 'mixpanel-browser';
import { EventAnalytics, EventAnalyticsAdmin } from './eventAnalytics';
import { env } from '@/constants';

export class MixPanelAnalytics implements EventAnalytics {
  private admin: EventAnalyticsAdmin | undefined;
  private defaultProperties = {};

  constructor() {
    mixpanel.init(env.APP_MIXPANEL_TOKEN, {
      debug: false,
      track_pageview: false,
      persistence: 'localStorage',
      record_sessions_percent: Number(env.APP_MIXPANEL_RECORD_SESSIONS_PERCENT),
    });
  }

  setAdmin(admin: EventAnalyticsAdmin): void {
    this.admin = admin;
  }

  setDefaultProperties(properties: { [key: string]: any } = {}): void {
    this.defaultProperties = properties;
  }

  saveProfile(properties: { [key: string]: any }): void {
    mixpanel.people.set(properties);
  }

  identify(uniqueId: string): void {
    mixpanel.identify(uniqueId);
  }

  track(eventName: string, properties: { [key: string]: any } = {}): void {
    if (this.admin) {
      properties.adminEmail = this.admin.email;
    }
    mixpanel.track(eventName, { ...this.defaultProperties, ...properties });
  }

  trackPageView(pageView: string): void {
    const properties: any = { event_name: 'PageView' };
    if (this.admin) {
      properties.adminEmail = this.admin.email;
    }
    mixpanel.track_pageview({ page: pageView }, { ...this.defaultProperties, ...properties });
  }

  reset(): void {
    mixpanel.reset();
  }
}
