import { cn } from '@lib-atria/ui-toolkit';
import { cubicBezier, motion } from 'framer-motion';
import { useCallback } from 'react';

import { SidebarType } from '@/@types';
import { eventAnalytics } from '@/providers';
import { TRACK_EVENTS } from '@/providers/eventAnalytics/trackEvents';
import { SidebarVariants } from '../sidebar';
import { SidebarMobileCloseButton } from './sidebarMobileCloseButton';
import { SidebarMobileFooter } from './sidebarMobileFooter';
import { SidebarMobileList } from './sidebarMobileList';

type Props = {
  variant?: SidebarVariants;
  items: SidebarType.Item[];
  isVisible: boolean;
  onHide: VoidFunction;
  userName: string;
  isAdmin?: boolean;
  adminName?: string;
  hasMultiplePatients?: boolean;
  onNavigate: (url: string) => void;
  onSwitchMember?: VoidFunction;
  onSignOut: VoidFunction;
};

export function SidebarMobile({
  variant,
  items,
  isVisible,
  onHide,
  userName,
  isAdmin = false,
  adminName,
  hasMultiplePatients,
  onNavigate,
  onSwitchMember,
  onSignOut,
}: Props) {
  const handleOnNavigation = useCallback(
    (itemClicked: SidebarType.Item) => {
      const isNotParent = !itemClicked.items?.length;
      if (isNotParent) {
        eventAnalytics.track(TRACK_EVENTS.SIDEBAR_BUTTON_CLICKED, {
          section: itemClicked.label,
        });
      }
      const url = itemClicked.url;
      if (url) {
        onNavigate(url);
        onHide();
        return;
      }
    },
    [onHide, onNavigate]
  );

  return (
    <motion.aside
      initial='closed'
      animate={isVisible ? 'open' : 'closed'}
      exit='closed'
      variants={{ open: { x: 0 }, closed: { x: '-100%' } }}
      transition={{ duration: 0.5, ease: cubicBezier(0.65, 0, 0.35, 1) }}
      className={cn(
        'fixed w-full h-screen max-h-screen z-30 bg-repeat flex flex-col atria-scrollbar overflow-y-auto',
        variant === 'forest' && 'bg-noise-forest-100',
        variant === 'gray' && 'bg-noise-white'
      )}
    >
      <div className='w-full h-full flex flex-col items-center justify-center py-4 gap-4 relative pl-[17px] overflow-x-hidden overflow-y-auto'>
        <div
          className={cn(
            'w-full h-[100px] absolute left-0 top-0 bg-gradient-to-b  to-transparent to-95%',
            variant === 'forest' && 'from-[#3A4C3B]',
            variant === 'gray' && 'from-white'
          )}
        ></div>
        <SidebarMobileCloseButton onHide={onHide} variant={variant} />
        <SidebarMobileList
          variant={variant}
          items={items}
          isAdmin={isAdmin}
          adminName={adminName}
          onClickItem={handleOnNavigation}
          onSignOut={onSignOut}
          onSwitchMember={onSwitchMember}
          hasMultiplePatients={hasMultiplePatients}
        />
        <div
          className={cn(
            'w-full h-[200px] absolute left-0 bottom-0 bg-gradient-to-b from-5% from-transparent via-30%  to-95% ',
            variant === 'forest' && 'via-[#3A4C3B] to-[#3A4C3B]',
            variant === 'gray' && 'via-white to-white'
          )}
        ></div>
        <SidebarMobileFooter variant={variant} isAdmin={isAdmin} patientName={userName} />
      </div>
    </motion.aside>
  );
}
