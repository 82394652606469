import React, { useCallback, useMemo, useState } from 'react';

import { CustomTableHeader, CustomTableHeaderAction } from '@/@types';
import { cn, TextAlignment, Tooltip } from '@lib-atria/ui-toolkit';
import { AnimatePresence } from 'framer-motion';
import { useMediaQuery } from 'react-responsive';
import { DialogMobile } from '../_common';
import { CustomTableChevron } from './customTableChevron';

type SortedByType = {
  [key: string]: 'asc' | 'desc';
};

type Props = {
  headers: CustomTableHeader[];
  headerActions?: CustomTableHeaderAction[];
  sortable: string[];
  index: number;
  header: any;
  onSort: (key: string, ordering: 'asc' | 'desc') => void;
};

export function CustomTableHeaderItem({
  headers,
  headerActions,
  sortable,
  index,
  header,
  onSort,
}: Props) {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [sortedBy, setSortedBy] = useState<SortedByType | undefined>(undefined!);
  const isSortable = useMemo(() => sortable.includes(header.key), [header.key, sortable]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOnSorting = useCallback(
    (key: string, sortKey?: string) => {
      if (!isSortable) return;
      setSortedBy((prev) => {
        const ordering = prev?.[key] ? (prev[key] === 'asc' ? 'desc' : 'asc') : 'asc';
        onSort(sortKey || key, ordering);
        return { [key]: ordering };
      });
    },
    [isSortable, onSort]
  );

  const handleOpenDialog = useCallback(() => {
    setIsDialogOpen(true);
  }, []);

  const renderTitle = () => {
    const title = (
      <span
        className={cn('uppercase', {
          'border-gray-500 border-b pb-0.5 mt-0.5': !!header.tooltip,
        })}
      >
        {header.label}
      </span>
    );

    if (isMobile || !header.tooltip) return title;
    return (
      <Tooltip textAligment={TextAlignment.Left} text={header.tooltip}>
        {title}
      </Tooltip>
    );
  };

  return (
    <th
      key={header.key ? header.key : `header-${index}`}
      className={cn(
        'duration-300 px-10 py-[26px] border-fern border-l-[1px]',
        isSortable && 'cursor-pointer hover:bg-black/5 ',
        index === headers.length - 1 && !headerActions && 'pr-8',
        header.width && [`min-w-[${header.width}]`]
      )}
      onClick={() => (isMobile ? handleOpenDialog() : handleOnSorting(header.key, header.sortKey))}
    >
      <div className='flex items-center justify-start gap-2 text-white text-xs text-left'>
        {renderTitle()}
        <CustomTableChevron
          animate={
            sortedBy?.[header.key] ? (sortedBy[header.key] === 'asc' ? 'asc' : 'desc') : 'invisible'
          }
        />
      </div>
      <AnimatePresence>
        {isDialogOpen && (
          <DialogMobile
            item={
              <div className='flex space-x-5 items-center pb-6'>
                <p>{header.tooltip}</p>
              </div>
            }
            handleOnClose={(event?: React.ChangeEvent) => {
              event?.preventDefault();
              event?.stopPropagation();
              setIsDialogOpen(false);
            }}
          />
        )}
      </AnimatePresence>
    </th>
  );
}
