import { cn, TextLayout } from '@lib-atria/ui-toolkit';
import { useMemo } from 'react';

type Props = {
  patientName: string;
};

export function Greetings({ patientName }: Props) {
  const greetings = useMemo(() => {
    const hours = new Date().getHours();
    if (hours < 12) {
      return 'Good morning';
    } else if (hours < 17) {
      return 'Good afternoon';
    }
    return 'Good evening';
  }, []);

  return (
    <h1
      className={cn(
        TextLayout.h1.scto,
        'text-forest-100 text-[44px] lg:text-[76px] leading-[48px] lg:leading-[72px] -tracking-[2.2px] lg:-tracking-[3.8px]'
      )}
    >
      {greetings}, {patientName}
    </h1>
  );
}
