import { CmsService } from '@/services';
import { useCallback } from 'react';

export function useLocations() {
  const findAllLocations = useCallback(async () => {
    try {
      const { data } = await CmsService.findAllLocations();
      return data;
    } catch {
      return null;
    }
  }, []);

  return {
    findAllLocations,
  };
}
