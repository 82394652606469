import { DateHelper } from '@/helper';
import { TrendChart, TrendChartType } from '@lib-atria/ui-toolkit';
import { useMemo } from 'react';

type ReferenceRange = {
  operator: string;
  range: {
    min: number;
    max: number;
  };
  statuses: string;
  optimal: boolean;
};

type Range = { operator?: TrendChartType.Operator; min?: number; max?: number };

type Trend = {
  documentId: number;
  value: number | string;
  units: string;
  status: string;
  referenceRange: ReferenceRange[] | Range;
  date: string;
};

type Props = {
  trend: Trend[];
  width?: number;
  height?: number;
  showLabels?: boolean;
  unitGroups?: string[][];
  hideWhenEmpty?: boolean;
};

type ChartData = {
  data: Array<{ date: Date; value: number; units?: string; status: string }>;
  thresholds: {
    optimal: {
      operator?: TrendChartType.Operator;
      range: Range;
      label: string;
    };
    good?: {
      operator?: TrendChartType.Operator;
      range: {
        min: number;
        max: number;
      };
      label: string;
    };
  };
};

const parseNumericValue = (value: string | number): number | null => {
  if (value === null || value === undefined || value === '') return null;
  if (typeof value === 'number') return isNaN(value) ? null : value;

  const cleanValue = value.replace(/>|</g, '');
  const parsed = Number(cleanValue);
  return isNaN(parsed) ? null : parsed;
};

export function ScorecardChartRow({
  trend,
  width,
  height,
  showLabels,
  unitGroups = [],
  hideWhenEmpty = false,
}: Props) {
  const { data, thresholds } = useMemo(() => {
    const chartData: ChartData = {
      data: [],
      thresholds: {
        optimal: { operator: 'range', range: { min: 0, max: 0 }, label: '' },
      },
    };

    if (!trend || typeof trend === 'string' || trend.length === 0) {
      return chartData;
    }

    const validTrend = trend.filter((item) => {
      const numericValue = parseNumericValue(item.value);
      return numericValue !== null;
    });

    if (validTrend.length === 0) return chartData;

    const latestResult = validTrend.reduce((previous: Trend | null, current: Trend) => {
      if (!previous) {
        return current;
      }

      return DateHelper.getDate(previous.date) > DateHelper.getDate(current.date)
        ? previous
        : current;
    }, null);

    if (!latestResult?.referenceRange || Number.isNaN(Number(latestResult.value))) return chartData;

    const getFilteredTrend = (): Trend[] => {
      if (unitGroups.length > 0) {
        const unitGroup = unitGroups.find((group) => group.includes(latestResult.units));
        return unitGroup
          ? validTrend.filter(({ units }) => unitGroup.includes(units))
          : validTrend.filter(({ units }) => units === latestResult.units);
      }
      return validTrend.filter(({ units }) => units === latestResult.units);
    };
    const filteredTrend = getFilteredTrend();

    const maxValue = Number(
      filteredTrend
        .map(({ value }) => value)
        .reduce((max, value) => (value > max ? value : max), 0)
        .toString()
        .replace(/>|</g, '')
    );

    if (Array.isArray(latestResult.referenceRange)) {
      const referenceRange = (latestResult.referenceRange as ReferenceRange[]).find(
        (range) => range.optimal && range.statuses !== 'Highly Optimal'
      );
      const highlyOptimalRange = (latestResult.referenceRange as ReferenceRange[]).find(
        (range) => range.statuses === 'Highly Optimal'
      );
      if (highlyOptimalRange && referenceRange) {
        const highlyOptimalMin = highlyOptimalRange.range.min;
        const highlyOptimalMax = highlyOptimalRange.range.max;

        if (highlyOptimalMin > referenceRange.range.max) {
          chartData.thresholds.good = {
            range: {
              min: highlyOptimalMin,
              max: highlyOptimalMax,
            },
            operator: highlyOptimalRange.operator as TrendChartType.Operator,
            label: highlyOptimalRange.statuses,
          };

          chartData.thresholds.optimal = {
            operator: referenceRange.operator as TrendChartType.Operator,
            range: {
              min: referenceRange.range.min,
              max: referenceRange.range.max,
            },
            label: referenceRange.statuses,
          };
        } else {
          chartData.thresholds.good = {
            operator: referenceRange.operator as TrendChartType.Operator,
            range: {
              min: referenceRange.range.min,
              max: referenceRange.range.max,
            },
            label: referenceRange.statuses,
          };

          chartData.thresholds.optimal = {
            range: {
              min: highlyOptimalMin,
              max: highlyOptimalMax,
            },
            operator: highlyOptimalRange.operator as TrendChartType.Operator,
            label: highlyOptimalRange.statuses,
          };
        }
      } else if (referenceRange) {
        const referenceRangeOptimal = validTrend
          .map(({ referenceRange: referenceRangeArray }) =>
            Array.isArray(referenceRangeArray)
              ? (referenceRangeArray as ReferenceRange[]).filter(
                  (range) => range.optimal && range.statuses !== 'Highly Optimal'
                )
              : []
          )
          .flat();

        if (referenceRangeOptimal.length > 0) {
          const referenceRangeMax = referenceRangeOptimal[0].range.max ?? maxValue;

          chartData.thresholds.optimal.range.min = referenceRangeOptimal[0].range.min ?? 0;
          chartData.thresholds.optimal.range.max = referenceRangeMax;
          chartData.thresholds.optimal.operator =
            referenceRange.operator as TrendChartType.Operator;
          chartData.thresholds.optimal.label = referenceRange.statuses;
        } else {
          chartData.thresholds.optimal.label = 'Normal';
        }
      }
    } else {
      const referenceRange = latestResult.referenceRange;
      const rangeMin = (referenceRange as Range).min || 0;
      const rangeMax = (referenceRange as Range).max || maxValue;

      chartData.thresholds.optimal.range.max = Math.max(rangeMax, rangeMin);
      chartData.thresholds.optimal.range.min = rangeMin;
      chartData.thresholds.optimal.operator = latestResult.referenceRange.operator || 'range';
      chartData.thresholds.optimal.label = ['optimal', 'normal'].includes(
        latestResult.status?.toLowerCase()
      )
        ? latestResult.status
        : 'Normal';
    }
    chartData.data = filteredTrend
      .filter(({ value }) => {
        const parsed = parseNumericValue(value);
        return parsed !== null;
      })
      .map(({ value, units, date, status }) => ({
        value: parseNumericValue(value) ?? 0,
        units,
        date: DateHelper.getDate(date),
        status,
        valueLabel: value,
      }));

    return chartData;
  }, [unitGroups, trend]);

  return (
    <TrendChart
      data={data}
      thresholds={thresholds}
      width={width || 160}
      height={height || 100}
      hideLabels={!showLabels}
      hideWhenEmpty={hideWhenEmpty}
    />
  );
}
