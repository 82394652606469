import classNames from 'classnames';
import { cubicBezier, motion } from 'framer-motion';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { CopyIcon } from '../icons/copyIcon';

interface Props {
  children: ReactNode;
  copyText?: string;
}

interface MessageProps {
  className: string;
}

export function CopyToClipboard({ children, copyText }: Props) {
  const [copied, setCopied] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const divRef = useRef<HTMLDivElement>(null);
  const iconRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    const div = divRef.current;
    const icon = iconRef.current;

    const showIcon = () => {
      if (icon) icon.style.display = 'block';
    };

    const hideIcon = () => {
      if (icon) {
        icon.style.display = 'none';
        setCopied(false);
      }
    };

    if (div) {
      div.addEventListener('mouseenter', showIcon);
      div.addEventListener('mouseleave', hideIcon);
    }

    return () => {
      if (div) {
        div.removeEventListener('mouseenter', showIcon);
        div.removeEventListener('mouseleave', hideIcon);
      }
    };
  }, []);

  const copyToClipboard = useCallback(() => {
    const textToCopy = copyText ? copyText : Array.isArray(children) ? children.join('') : children;
    navigator.clipboard.writeText(textToCopy as string);
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 3000);
  }, [children, setCopied, copyText]);

  const Message: React.FC<MessageProps> = ({ className }: MessageProps) => (
    <span className={classNames(['inline-flex items-center justify-center', className])}>
      <i className='pi pi-check mr-2' />
      Copied
    </span>
  );

  return (
    <div
      ref={divRef}
      className='flex items-center cursor-pointer relative'
      onClick={copyToClipboard}
    >
      <span className=''>{children}</span>
      <span
        ref={iconRef}
        style={{ display: copied ? 'block' : 'none' }}
        className='absolute -right-5 top-1/2 transform -translate-y-1/2'
      >
        <CopyIcon width={15} height={15} className='fill-experimental-forest absolute right-0' />
        {copied &&
          (isMobile ? (
            <motion.div
              className='fixed bottom-0 left-0 bg-gradient-to-t to-transparent from-white h-44 w-full'
              transition={{ duration: 0.5, ease: cubicBezier(0.65, 0, 0.35, 1) }}
              initial={{ y: 20 }}
              animate={{ y: 0 }}
            >
              <Message className='fixed left-1/2 bottom-2 transform -translate-x-1/2 -translate-y-1/2 bg-experimental-forest px-8 py-3 rounded-lg text-white' />
            </motion.div>
          ) : (
            <motion.div
              className='absolute top-6'
              transition={{ duration: 0.5, ease: cubicBezier(0.65, 0, 0.35, 1) }}
              initial={{ y: 20 }}
              animate={{ y: 0 }}
            >
              <Message className='bg-experimental-forest px-4 py-2 rounded-lg text-white top-6' />
            </motion.div>
          ))}
      </span>
    </div>
  );
}
