import { BottomSheet } from '@/components/bottomSheet';
import { MaterialIcons } from '@/components/icons';
import { TextLayout } from '@/components/text';
import { Checkbox, cn } from '@lib-atria/ui-toolkit';
import { AnimatePresence, motion } from 'framer-motion';
import { InputTextarea } from 'primereact/inputtextarea';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

type Props = {
  options?: string[];
  defaultSelected?: string[];
  onItemsSelect: (value: string[]) => void;
  placeholder?: string;
  selectPrefixLabel?: string;
};

export function DialogModalFormMultiSelect({
  options,
  defaultSelected,
  onItemsSelect,
  placeholder = 'Select',
  selectPrefixLabel,
}: Props) {
  const [selected, setSelected] = useState<string[]>(defaultSelected || []);
  const [isOpen, setIsOpen] = useState(false);

  const inputRef = useRef<HTMLTextAreaElement | null>(null);
  const optionsRef = useRef<HTMLDivElement>(null);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (optionsRef.current && !optionsRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [optionsRef]);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const toggleItem = useCallback((item: string) => {
    setSelected((prev) => {
      const copy = [...prev];
      const index = copy.findIndex((i) => i === item);
      if (index > -1) {
        return copy.filter((i) => i !== item);
      }
      copy.push(item);
      return copy;
    });
  }, []);

  const isSelected = useCallback(
    (item: string) => {
      return selected.includes(item);
    },
    [selected]
  );

  const inputValue = useMemo((): string => {
    if (selected.length === 0) {
      return placeholder;
    }
    return `${selectPrefixLabel} ${(selected || [])?.join(' and ')}`;
  }, [placeholder, selectPrefixLabel, selected]);

  useEffect(() => {
    onItemsSelect(selected);
    if (inputRef?.current) {
      inputRef.current.style.height = selected.length > 1 ? `${selected.length * 35}px` : '50px';
    }
  }, [onItemsSelect, selected]);

  return (
    <>
      <div className='relative'>
        <InputTextarea
          className='bg-kelp-dark text-white border-none w-full resize-none overflow-auto scrollbar-hidden cursor-pointer pr-6'
          onClick={() => setIsOpen((prev) => !prev)}
          value={inputValue}
          ref={inputRef}
        />
        <button
          type='button'
          aria-controls='options-list'
          className='absolute right-3 top-[1.6rem] transform -translate-y-1/2 focus:outline-none'
          onClick={() => setIsOpen((prev) => !prev)}
        >
          <MaterialIcons
            name='keyboard_arrow_up'
            className={cn(
              'text-white transition-transform duration-300',
              isOpen ? 'rotate-0' : 'rotate-180'
            )}
          />
        </button>
      </div>
      <AnimatePresence>
        {isOpen &&
          (!isMobile ? (
            <motion.div
              className='absolute z-10 mt-2 box-content md:bottom-20 bottom-28 flex md:max-h-[200px] max-h-[300px] transition duration-300 !w-fit flex-col overflow-auto rounded-lg bg-white text-forest-100 p-2 pr-3'
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={(e) => e.stopPropagation()}
              ref={optionsRef}
            >
              <div className='atria-scrollbar-tiny z-10 mt-2 md:bottom-20 bottom-28 flex md:max-h-[200px] max-h-[300px] transition duration-300 w-full flex-col overflow-auto rounded-lg bg-white text-forest-100 pr-1'>
                {options?.map((option) => (
                  <div
                    className='flex *:select-none [&>*:nth-child(even)]:active:scale-90 transition-all  w-full justify-between items-center align-middle cursor-pointer rounded-md pr-3 text-forest-100 hover:bg-product-sand-100'
                    key={option}
                  >
                    <div
                      className={cn(TextLayout.body2, 'w-full py-3 pl-3 pr-3')}
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleItem(option);
                      }}
                    >
                      <span className='w-full'>{option}</span>
                    </div>
                    <Checkbox
                      checked={isSelected(option)}
                      variant='quaternary'
                      onChange={(e) => {
                        e.stopPropagation();
                        toggleItem(option);
                      }}
                    />
                  </div>
                ))}
              </div>
            </motion.div>
          ) : (
            <AnimatePresence>
              <BottomSheet isVisible={isOpen} onClose={onClose} headerTitle='' closeIcon={true}>
                <div
                  className='text-base atria-scrollbar flex flex-col overflow-auto rounded-lg text-white px-5 py-4'
                  ref={optionsRef}
                >
                  {options?.map((option) => (
                    <li
                      key={option}
                      className={cn(
                        TextLayout.body2,
                        'w-full *:select-none [&>*:nth-child(even)]:active:scale-90 transition-all flex justify-between items-center align-middle cursor-pointer rounded-md px-3 py-4 text-white  hover:bg-kelp-dark'
                      )}
                      onClick={() => toggleItem(option)}
                    >
                      <span className='w-3/4'>{option}</span>
                      <Checkbox
                        checked={isSelected(option)}
                        variant='quaternary'
                        onChange={(e) => {
                          e.stopPropagation();
                          toggleItem(option);
                        }}
                      />
                    </li>
                  ))}
                </div>
              </BottomSheet>
            </AnimatePresence>
          ))}
      </AnimatePresence>
    </>
  );
}
