import { Auth } from '@/@types';
import { MarketingCard } from '@lib-atria/ui-toolkit';
import { useNavigate } from 'react-router-dom';
import { withPermission } from '../Permissions';
import image1 from './lab-trends1.png';
import image2 from './lab-trends2.png';
import image3 from './lab-trends3.png';
import { useCallback } from 'react';
import { eventAnalytics } from '@/providers';
import { TRACK_EVENTS } from '@/providers/eventAnalytics/trackEvents';

export function LabTrendsCard() {
  const navigate = useNavigate();

  const handleOnClick = useCallback(() => {
    eventAnalytics.track(TRACK_EVENTS.HOME_PAGE_CARD_CLICKED, {
      cardName: 'Lab trends',
      buttonClicked: 'View all lab trends',
    });
    navigate('/results/lab-trends');
  }, [navigate]);

  const Card = withPermission(MarketingCard, Auth.Permissions.UserPermissions.VIEW_SCORECARD);

  return (
    <Card
      title='Lab trends'
      description='See all of your lab results over time, including the key biomarkers that Atria doctors have identified as important for longevity.'
      buttonLabel='View all lab trends'
      onClick={handleOnClick}
      list={[
        <img key={0} src={image1} />,
        <img key={1} src={image2} />,
        <img key={2} src={image3} />,
      ]}
    />
  );
}
