import { cn } from '@lib-atria/ui-toolkit';
import { useCallback } from 'react';
import { useMediaQuery } from 'react-responsive';

type TableProps = {
  headers: CustomTableHeader[];
  items: CustomTableItems[];
};
type CustomTableHeader = {
  label: string;
  key: string;
  width?: number | string;
};

type CustomTableItems = {
  [key: string]: any;
};

export function ScorecardCustomTable({ headers, items }: TableProps) {
  const isDesktopOrLargeTablet = useMediaQuery({ query: '(min-width: 1024px)' });
  const getValueColor = useCallback((data: string, ranges: any, status: string) => {
    const value = Number(data.replace(/>|</g, ''));
    if (Array.isArray(ranges)) {
      const optimalRange = ranges.find(({ statuses }) => ['Optimal', 'Normal'].includes(statuses));
      const highlyOptimalRange = ranges.find(({ statuses }) =>
        ['Highly Optimal', 'Good'].includes(statuses)
      );
      if (
        highlyOptimalRange &&
        value > highlyOptimalRange.range.min &&
        value < highlyOptimalRange.range.max
      ) {
        return 'bg-neue-kelp-300';
      }
      if (
        optimalRange &&
        (!optimalRange.range.min || value >= optimalRange.range.min) &&
        (!optimalRange.range.max || value <= optimalRange.range.max)
      ) {
        return highlyOptimalRange ? 'bg-neue-kelp-100' : 'bg-neue-kelp-300';
      }
    } else if (!ranges || status) {
      const statusLower = status?.toLowerCase();
      if (['low', 'high', 'out of range'].includes(statusLower)) {
        return 'bg-rust';
      } else if (['normal', 'optimal', 'highly optimal'].includes(statusLower)) {
        return 'bg-neue-kelp-300';
      } else {
        return 'bg-product-gray-300';
      }
    } else if (
      ranges &&
      (!ranges.min || ranges.min <= value) &&
      (!ranges.max || ranges.max >= value)
    ) {
      return 'bg-neue-kelp-300';
    }
    return 'bg-rust';
  }, []);
  return (
    <>
      <table className='table-fixed w-full border-collapse '>
        {isDesktopOrLargeTablet && (
          <thead>
            <tr className='border-b border-black'>
              {headers.map((header) => (
                <th
                  key={header.key}
                  style={{ width: header.width || 'auto' }}
                  className='px-4 py-2 text-sm font-medium text-neue-kelp-400 text-center border-b border-black'
                >
                  {header.label}
                </th>
              ))}
            </tr>
          </thead>
        )}
        <tbody className=' border border-gray-400'>
          {items.length === 0 ? (
            <tr>
              <td
                colSpan={headers.length}
                className='border border-gray-400 px-4 py-2 text-center text-gray-500'
              >
                -
              </td>
            </tr>
          ) : (
            items.map((item, rowIndex) => (
              <tr key={rowIndex} className='border'>
                {headers.map((header) => {
                  return (
                    <td
                      key={header.key}
                      className='px-2 lg:px-4 py-3 text-xs lg:text-sm font-medium text-neue-kelp-400 border border-gray-400 text-center'
                    >
                      {header.key === 'value' ? (
                        <div className='text-neue-kelp-400 leading-4 -tracking-[0.35px]'>
                          <span
                            className={cn(
                              'inline-block rounded-full w-2 h-2 text-white',
                              getValueColor(
                                item[header.key].value,
                                item[header.key].range,
                                item.status
                              )
                            )}
                          />
                          <span className='inline-block mx-1'>{item[header.key].value}</span>
                          <span className='inline-block text-gray-500 font-normal'>
                            {item[header.key].units}
                          </span>
                        </div>
                      ) : (
                        <p>{item[header.key]}</p>
                      )}
                    </td>
                  );
                })}
              </tr>
            ))
          )}
        </tbody>
      </table>
    </>
  );
}
