import { useCallback } from 'react';

import { DashboardService } from '@/services';

export function useDashboard() {
  const findSummary = useCallback(async (patientId: number) => {
    const { data } = await DashboardService.findSummary({ patientId });
    return data;
  }, []);

  return {
    findSummary,
  };
}
