import { cn } from '@lib-atria/ui-toolkit';

import { SidebarVariants, TextLayout } from '@/components';

type Props = {
  variant?: SidebarVariants;
  onClick: VoidFunction;
  isAdmin?: boolean;
  adminName?: string;
  hasMultiplePatients?: boolean;
  onSwitchMember?: VoidFunction;
};

export function SidebarMobileLogoutButton({
  variant,
  onClick,
  isAdmin = false,
  adminName,
  hasMultiplePatients,
  onSwitchMember,
}: Props) {
  if (isAdmin) {
    return (
      <div className='w-full flex flex-col mt-12'>
        <p
          className={cn(
            TextLayout.body1,
            'font-medium opacity-50 -tracking-[0.16px]',
            variant === 'forest' && 'text-product-sand-100',
            variant === 'gray' && 'text-product-forest-100'
          )}
        >
          Connected as {adminName}
        </p>
        <a
          id='switchMemberButton'
          href='#'
          className={cn(
            TextLayout.body1,
            'block font-medium',
            variant === 'forest' && 'text-product-sand-100',
            variant === 'gray' && 'text-product-forest-100'
          )}
          onClick={onSwitchMember}
        >
          Switch member
        </a>
      </div>
    );
  }

  if (hasMultiplePatients) {
    return (
      <div className='w-full flex flex-col gap-4 mt-12'>
        <button
          id='switchAccountButton'
          className={cn(
            TextLayout.body1,
            variant === 'forest' && 'text-product-sand-100',
            variant === 'gray' && 'text-product-forest-100'
          )}
          onClick={onSwitchMember}
        >
          Switch account
        </button>
        <button
          id='signOutButton'
          className={cn(
            TextLayout.body1,
            variant === 'forest' && 'text-product-sand-100',
            variant === 'gray' && 'text-product-forest-100'
          )}
          onClick={onSwitchMember}
        >
          Logout
        </button>
      </div>
    );
  }

  return (
    <a
      id='signOutButton'
      href='#'
      onClick={onClick}
      className={cn(
        TextLayout.body1,
        'font-medium -tracking-[0.16px] mt-12',
        variant === 'forest' && 'text-white',
        variant === 'gray' && 'text-product-forest-100'
      )}
    >
      Logout
    </a>
  );
}
