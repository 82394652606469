import { TextAlignment, Tooltip } from '@lib-atria/ui-toolkit';
import { ReactNode } from 'react';

type Props = {
  text: string;
  children: ReactNode | string;
};

export function ScoreCardTableHeader({ text, children }: Props) {
  return (
    <div className='whitespace-normal'>
      <Tooltip textAligment={TextAlignment.Left} text={text}>
        {children}
      </Tooltip>
    </div>
  );
}
