import { Button, cn, TextLayout } from '@lib-atria/ui-toolkit';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { MaterialIcons } from '@/components/icons';
import { DateHelper } from '@/helper';
import { formatDateLong } from '@/utils/dateFormat';

const variantToMaterialIconMap: Record<string, string> = {
  Video: 'tv_signin',
  Home: 'home',
  Phone: 'phone',
};

type Props = {
  date: string;
  startTime: string;
  endTime: string;
  modifyAppointment: VoidFunction;
  variant?: 'primary';
  location?: string;
  className?: string;
};

export function DateModule({
  variant = 'primary',
  date,
  startTime,
  endTime,
  modifyAppointment,
  location = '',
  className,
}: Props) {
  const dateObj = useMemo(() => formatDateLong(date), [date]);

  const startLong = useMemo(() => {
    const startDateObj = DateTime.fromISO(startTime);
    if (startDateObj.toJSDate().getMinutes() > 0) {
      return DateHelper.formatTimeToUpperMeridian(startDateObj.toFormat('h:mma'));
    }
    return DateHelper.formatTimeToUpperMeridian(startDateObj.toFormat('ha'));
  }, [startTime]);

  const endLong = useMemo(() => {
    const endDateObj = DateTime.fromISO(endTime);
    if (endDateObj.toJSDate().getMinutes() > 0) {
      return DateHelper.formatTimeToUpperMeridian(endDateObj.toFormat('h:mma'));
    }
    return DateHelper.formatTimeToUpperMeridian(endDateObj.toFormat('ha'));
  }, [endTime]);

  const borderAndColor = 'border-forest-100 text-forest-100';

  return (
    <div
      className={cn(
        'flex min-h-[360px] w-full flex-col items-center gap-3 rounded-lg bg-noise-fern bg-repeat p-3 pb-0 lg:min-h-[456px] lg:gap-5',
        variant === 'primary' && 'rounded-[12px] bg-neue-kelp-400',
        className
      )}
    >
      <div
        className={cn(
          'flex w-full items-center justify-center rounded-lg px-20 py-10 h-[260px] lg:h-[340px]',
          variant === 'primary' && 'items-start justify-start bg-neue-kelp-600 p-5 lg:p-6',
          borderAndColor
        )}
      >
        <div className={cn('flex flex-col justify-between gap-3 lg:gap-0 h-full', borderAndColor)}>
          <div className='flex flex-col gap-1 lg:gap-3'>
            <span
              className={cn(
                TextLayout.callout3,
                'text-[18px] font-medium leading-7 tracking-[-0.18px] text-white lg:text-xl lg:tracking-[-0.2px]'
              )}
            >
              {dateObj.weekdayLong}
            </span>

            <div
              className={cn(
                TextLayout.h1.scto,
                'max-w-[900px] text-[44px] font-medium leading-[48px] tracking-[-2.2px] text-white lg:text-[76px] lg:leading-[72px] lg:tracking-[-3.8px]'
              )}
            >
              {dateObj.monthShort} {dateObj.day}, <br /> {startLong} - {endLong}
            </div>
          </div>

          {location && (
            <div className='flex items-center gap-[6px] lg:gap-[9px]'>
              <MaterialIcons
                name={variantToMaterialIconMap[location] ?? 'location_on'}
                className='h-[24px] w-[16px] text-white opacity-50'
              />

              <span
                className={cn(
                  TextLayout.callout3,
                  'text-[18px] font-medium leading-7 text-white opacity-50 lg:text-xl'
                )}
              >
                {variantToMaterialIconMap[location] ? location : `Atria ${location}`}
              </span>
            </div>
          )}
        </div>
      </div>

      <div className='flex w-full flex-col items-center justify-center gap-3 lg:flex-row px-2 lg:px-6'>
        <Button
          theme='light'
          variant='quaternary'
          size='large'
          label='Modify appointment'
          onClick={modifyAppointment}
          className='w-full h-[64px]'
        />
      </div>
    </div>
  );
}
