import { DateTime } from 'luxon';

const specialCaseMonths: { [key: string]: string } = {
  Mar: 'March',
  Apr: 'April',
  Jun: 'June',
  Jul: 'July',
  Sep: 'Sept',
};

const specialCaseMonthsKeys = Object.keys(specialCaseMonths);

function formatDateToDisplay(date: DateTime) {
  const displayDate = date.toFormat('LLL d, y');
  const [month] = displayDate.split(' ');
  const parsedMonth = parseMonth(month);
  if (!parsedMonth) {
    return displayDate;
  }
  return displayDate.replace(month, parsedMonth);
}

function parseMonth(month: string) {
  if (!specialCaseMonthsKeys.includes(month)) {
    return;
  }
  return specialCaseMonths[month];
}

function formatTimeToUpperMeridian(time: string) {
  return time.toLowerCase().replace('am', ' a.m.').replace('pm', ' p.m.');
}

function formatDateTimeToDisplay(date: DateTime) {
  const displayDate = date.toFormat('LLL d, y');
  const displayTime = formatTimeToUpperMeridian(date.toFormat('hh:mm a'));
  const result = `${displayDate}, ${displayTime}`;
  const [month] = displayDate.split(' ');
  const parsedMonth = parseMonth(month);
  if (!parsedMonth) {
    return result;
  }
  return result.replace(month, parsedMonth);
}

function getDate(date: Date | string) {
  return date
    ? DateTime.fromISO(new Date(date).toISOString(), { zone: 'utc' }).toJSDate()
    : DateTime.utc().toJSDate();
}

export const DateHelper = {
  formatDateToDisplay,
  formatTimeToUpperMeridian,
  formatDateTimeToDisplay,
  getDate,
};
