import { DateTime } from 'luxon';

export const formatMonth = (month: string): string => {
  const monthMap: { [key: string]: string } = {
    Jan: 'Jan',
    Feb: 'Feb',
    Mar: 'March',
    Apr: 'April',
    May: 'May',
    Jun: 'June',
    Jul: 'July',
    Aug: 'Aug',
    Sep: 'Sept',
    Oct: 'Oct',
    Nov: 'Nov',
    Dec: 'Dec',
  };

  return monthMap[month] || month;
};

export const formatDateLong = (date: string) => {
  const dt = DateTime.fromISO(date);
  return {
    day: dt.toFormat('d'),
    monthShort: formatMonth(dt.toFormat('MMM')),
    weekdayLong: dt.weekdayLong,
    year: dt.toFormat('y'),
  };
};
