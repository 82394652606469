import { Button } from '@lib-atria/ui-toolkit';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Resource, ResourceCategoryWithGeneral } from '@/@types';
import {
  Accordion,
  AccordionItem,
  AddressCard,
  AttachmentOptions,
  AttachmentViewer,
  PageTitle,
  UploadResourceSidebar,
} from '@/components';
import { useApplicationContext, useAuthContext, usePageContext, useToastContext } from '@/contexts';
import { useResources } from '@/hooks';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { eventAnalytics } from '@/providers';
import { TRACK_EVENTS } from '@/providers/eventAnalytics/trackEvents';

const attachmentViewerOptions: AttachmentOptions = {
  borderVisible: false,
  pageShadowVisible: true,
  pageRounded: true,
  toolbarVisible: false,
  loaderContainerClassName: 'max-w-[98%]',
};

export function ResourcesPage() {
  const { resources, getResources, allLocations, getAllLocations } = useApplicationContext();
  const { openResourceAttachmentInNewTab, deleteResource } = useResources();
  const { isStaffAdmin } = useAuthContext();
  const { setPageTitle } = usePageContext();
  const { toast } = useToastContext();
  const [isUploadSidebarVisible, setIsUploadSidebarVisible] = useState(false);
  const [isEditingResource, setIsEditingResource] = useState(false);
  const [editingResource, setEditingResource] = useState<Resource>();
  const [deletingResource, setDeletingResource] = useState<Resource>();
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);

  const groupedResources = useMemo(
    () =>
      resources?.reduce(
        (acc, item) => {
          if (!item.category) {
            if (!acc['GENERAL']) {
              acc['GENERAL'] = [];
            }

            acc['GENERAL'].push(item);

            return acc;
          }

          if (!acc[ResourceCategoryWithGeneral[item.category]]) {
            acc[ResourceCategoryWithGeneral[item.category]] = [];
          }

          acc[ResourceCategoryWithGeneral[item.category]].push(item);

          return acc;
        },
        {} as Record<ResourceCategoryWithGeneral, Resource[]>
      ),
    [resources]
  );

  const handleDocumentUpload = useCallback(() => {
    if (isEditingResource) {
      setIsEditingResource(false);
      setEditingResource(undefined);
    }
    setIsUploadSidebarVisible(false);
    getResources();
  }, [getResources, isEditingResource]);

  const handleOnEditResourceClick = useCallback((resource: Resource) => {
    setIsUploadSidebarVisible(true);
    setEditingResource(resource);
    setIsEditingResource(true);
  }, []);

  const handleHideResourcesSidebar = useCallback(() => {
    if (isEditingResource) {
      setIsEditingResource(false);
      setEditingResource(undefined);
    }

    setIsUploadSidebarVisible(false);
  }, [isEditingResource]);

  const handleOnDeleteResourceClick = useCallback((resource: Resource) => {
    setDeletingResource(resource);
    setIsDeleteDialogVisible(true);
  }, []);

  const handleOnCancelResourceDelete = useCallback(() => {
    setDeletingResource(undefined);
    setIsDeleteDialogVisible(false);
  }, []);

  const handleResourceDelete = useCallback(async () => {
    try {
      await deleteResource(deletingResource!.id);
      toast?.current.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Resource deleted successfully',
        life: 2500,
      });
      getResources();
    } catch {
      toast?.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Resource delete failed',
        life: 2500,
      });
    } finally {
      setIsDeleteDialogVisible(false);
    }
  }, [deleteResource, deletingResource, getResources, toast]);

  const onDownloadResource = useCallback(
    (resource: Resource) => {
      eventAnalytics.track(TRACK_EVENTS.RESOURCE_DOWNLOADED, {
        name: resource.resourceName,
      });
      openResourceAttachmentInNewTab(resource.id);
    },
    [openResourceAttachmentInNewTab]
  );

  const onAccordionItemOpen = useCallback((resource: Resource) => {
    eventAnalytics.track(TRACK_EVENTS.RESOURCE_VIEWED, {
      name: resource.resourceName,
    });
  }, []);

  useEffect(() => {
    setPageTitle('Resources');
    eventAnalytics.track(TRACK_EVENTS.RESOURCES_PAGE_VIEWED);
  }, [setPageTitle]);

  useEffect(() => {
    const hash = window.location.hash.substring(1);
    if (hash) {
      const element = document.getElementById(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  useEffect(() => {
    if (!resources) {
      getResources();
    }
  }, [getResources, resources]);

  useEffect(
    function firstLoad() {
      const loadInitialData = async () => {
        await getAllLocations();
      };

      loadInitialData();
    },
    [getAllLocations]
  );

  return (
    <>
      {isStaffAdmin && (
        <>
          <div className='flex justify-end'>
            <Button
              label='Upload new resource'
              icon='file_upload'
              onClick={() => setIsUploadSidebarVisible(true)}
            />
          </div>
          <UploadResourceSidebar
            visible={isUploadSidebarVisible}
            handleOnHide={handleHideResourcesSidebar}
            handleDocumentUpload={handleDocumentUpload}
            isEditing={isEditingResource}
            data={editingResource}
          />
          <ConfirmDialog
            draggable={false}
            visible={isDeleteDialogVisible}
            onHide={() => setIsDeleteDialogVisible(false)}
            message='Are you sure you want to delete this resource?'
            header='Delete resource'
            icon='pi pi-exclamation-triangle'
            accept={handleResourceDelete}
            reject={handleOnCancelResourceDelete}
          />
        </>
      )}
      <div className='w-full flex-1 flex flex-col gap-16 md:gap-[6.25rem]'>
        <PageTitle title='Atria resources' />
        <div id='locations'>
          <p className='text-experimental-forest font-medium text-base mb-4'>Locations</p>
          <div className='w-full grid gap-5 grid-cols-1 sm:grid-cols-2 justify-center'>
            {allLocations?.map((location, index) => (
              <AddressCard key={index} location={location} />
            ))}
          </div>
        </div>
        {!!resources?.length && (
          <div id='resources'>
            <h3 className='font-body text-base font-medium leading-6 text-product-forest-100 pb-5'>
              Resources
            </h3>
            <div className='grid gap-y-[20px]'>
              {groupedResources &&
                Object.values(ResourceCategoryWithGeneral).map(
                  (item) =>
                    groupedResources[item]?.length > 0 && (
                      <Accordion key={item} header={item}>
                        {groupedResources[item].map((resource) => (
                          <AccordionItem
                            key={resource.id}
                            id={resource.id}
                            title={resource.resourceName}
                            onOpen={() => onAccordionItemOpen(resource)}
                          >
                            <div className='w-full flex items-center justify-between'>
                              <Button
                                variant='tertiary'
                                label='Download'
                                onClick={() => onDownloadResource(resource)}
                              />
                              {isStaffAdmin && (
                                <div className='w-full flex items-center gap-x-[10px] justify-end'>
                                  <Button
                                    variant='primary'
                                    label='Edit'
                                    onClick={() => handleOnEditResourceClick(resource)}
                                  />
                                  <Button
                                    variant='secondary'
                                    label='Delete'
                                    onClick={() => handleOnDeleteResourceClick(resource)}
                                  />
                                </div>
                              )}
                            </div>

                            <div className='w-[calc(100%+22px)] min-h-[900px]'>
                              <AttachmentViewer
                                options={attachmentViewerOptions}
                                attachments={
                                  resource?.signedUrl
                                    ? [{ src: resource?.signedUrl, type: 'pdf' }]
                                    : []
                                }
                              />
                            </div>
                          </AccordionItem>
                        ))}
                      </Accordion>
                    )
                )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
