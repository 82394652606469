import { MemberPortal } from '@/@types';
import { ScorecardExpandedContent } from '@/components/scorecard/scorecardExpandedContent';
import { useAuthContext, usePageContext } from '@/contexts';
import { usePatients } from '@/hooks';
import { useCallback, useEffect, useState } from 'react';

type ScorecardList = MemberPortal.FindAllBiomarkers.AllAnalytesTableList;

type AllBiomarkersList = MemberPortal.FindAllBiomarkers.Response;

type AtriaTargetsList = MemberPortal.AtriaTargets.Response;

export function usePatientScoreCardPage() {
  const { setPageTitle } = usePageContext();
  const { patient } = useAuthContext();
  const { findPatientAllBiomarkers, findPatientAtriaTargets } = usePatients();
  const [isLoading, setIsLoading] = useState(false);
  const [allAnalytesCards, setAllAnalytesCards] = useState<ScorecardList | null>(null);
  const [atriaTargetsCards, setAtriaTargetsCards] = useState<ScorecardList | null>(null);

  const buildUnitGroups = useCallback(
    (
      biomarker: MemberPortal.AtriaTargets.Biomarker | MemberPortal.FindAllBiomarkers.BiomarkerItem
    ) => {
      let unitGroups: string[][] = [];
      if (biomarker.unitSpecification && biomarker.unitSpecification?.length > 0) {
        const unitsList =
          (biomarker.unitSpecification as MemberPortal.AtriaTargets.Biomarker['unitSpecification'])!.map(
            ({ units }) => units
          );
        unitGroups = unitsList.map((unitGroup) =>
          unitGroup.map(({ unit }) => unit.name.toLowerCase())
        );
      }
      return unitGroups;
    },
    []
  );

  const mappingAtriaTargets = useCallback(
    (data: AtriaTargetsList): ScorecardList => {
      const headers = data.headers.map((heads, idx) => {
        if (idx === 0) {
          const category = {
            key: 'category',
            label: 'Category',
            rowGroup: true,
          };
          return [category, ...heads];
        }
        return heads;
      });

      return [
        {
          ...data,
          headers: headers,
          items: data.items.map(([biomarker]) => {
            const unitGroups = buildUnitGroups(biomarker);
            return {
              ...biomarker,
              headers: headers,
              historical: biomarker.trend as any,
              unitGroups,
              expandableContent: ({ collapse }) => (
                <ScorecardExpandedContent
                  description={biomarker.description}
                  title={biomarker.metric!}
                  howToImprove={biomarker.howToImprove}
                  trend={biomarker.trend}
                  unitGroups={unitGroups}
                  isAtriaTarget
                  observationNote={biomarker.observationNote}
                  hasNotes={biomarker.hasNotes}
                  collapse={collapse}
                  status={biomarker.status}
                  atriaOptimalRange={biomarker.atriaOptimalRange}
                />
              ),
              __tag__: data.tag,
            };
          }),
        },
      ];
    },
    [buildUnitGroups]
  );

  const findAtriaTargets = useCallback(
    async (patientId: number) => {
      const atriaTargets = await findPatientAtriaTargets(patientId);
      if (atriaTargets) {
        const items = mappingAtriaTargets(atriaTargets);
        setAtriaTargetsCards(items);
      }
    },
    [findPatientAtriaTargets, mappingAtriaTargets]
  );

  const mappingAllAnalytes = useCallback(
    (cardsList: AllBiomarkersList): ScorecardList => {
      return cardsList.map((cardItem) => {
        const items = (cardItem.items || []).map((item) => {
          const unitGroups = buildUnitGroups(item);
          return {
            ...item,
            headers: cardItem.headers,
            unitGroups,
            expandableContent: ({ collapse }: { collapse: Function }) => (
              <ScorecardExpandedContent
                description={item?.description}
                title={item.metric || ''}
                howToImprove={item.howToImprove}
                trend={item.historical}
                unitGroups={unitGroups}
                observationNote={item.observationNote}
                hasNotes={item.hasNotes}
                collapse={collapse}
                status={item.status}
                referenceRangeLabel={item.referenceRangeLabel}
              />
            ),
            __tag__: cardItem.tag,
          };
        });
        items.sort((a, b) => (a.metric || '').localeCompare(b.metric || ''));
        return { ...cardItem, items };
      });
    },
    [buildUnitGroups]
  );

  const findAllBiomarkers = useCallback(
    async (patientId: number) => {
      const allAnalytesResponse = await findPatientAllBiomarkers(patientId);
      if (allAnalytesResponse) {
        const cardsList = mappingAllAnalytes(allAnalytesResponse);
        setAllAnalytesCards(cardsList);
      }
    },
    [findPatientAllBiomarkers, mappingAllAnalytes]
  );

  const generateScoreCard = useCallback(
    async (patientId: number) => {
      setIsLoading(true);
      await Promise.all([findAllBiomarkers(patientId), findAtriaTargets(patientId)]);
      setIsLoading(false);
    },
    [findAllBiomarkers, findAtriaTargets]
  );

  useEffect(() => {
    setPageTitle('Scorecard');
  }, [setPageTitle]);

  useEffect(() => {
    if (patient) {
      generateScoreCard(patient.id);
    }
  }, [generateScoreCard, patient]);

  return {
    isLoading,
    allAnalytesCards,
    generateScoreCard,
    atriaTargetsCards,
  };
}
