import { CircleIcon, cn } from '@lib-atria/ui-toolkit';
import { useMemo } from 'react';
import { TextLayout } from '../text';
import { formatDateLong } from '@/utils/dateFormat';

export function PastAppointmentsCard({ groupItem }: any) {
  const {
    weekdayLong: dayOfWeek,
    monthShort: month,
    day,
    year,
  } = useMemo(() => formatDateLong(groupItem.date), [groupItem.date]);

  const date = useMemo(() => {
    return `${month} ${day}, ${year}`;
  }, [month, day, year]);

  const location = useMemo(() => {
    if (!groupItem.location) {
      return 'Schedule';
    }
    if (groupItem.location === 'Telehealth') {
      return 'Video visit';
    }
    return groupItem.location;
  }, [groupItem.location]);

  const appointments = useMemo(() => {
    return [...groupItem.appointments].sort(
      (apptA, apptB) => new Date(apptA.date).getTime() - new Date(apptB.date).getTime()
    );
  }, [groupItem.appointments]);

  return (
    <div className='flex flex-col md:flex-row gap-3'>
      <div className='rounded-lg px-[20px] pt-[80px] md:pt-6 pb-[24px] bg-product-sand-100 w-full md:flex-1'>
        <p className={cn(TextLayout.callout3, 'text-gray-500 mb-3')}>{dayOfWeek}</p>
        <p className={cn(TextLayout.h2.scto, 'text-neue-kelp-400')}>{date}</p>
      </div>
      <div className='rounded-lg px-[20px] py-[24px] bg-product-sand-100 w-full md:flex-1 md:min-h-[320px]'>
        <div className='flex w-full flex-col'>
          {groupItem.doctor && (
            <h1 className={cn(TextLayout.callout3, 'text-product-forest-100')}>
              Led by Dr. {groupItem.doctor?.name}
            </h1>
          )}
          <p
            className={cn(
              TextLayout.callout3,
              groupItem.doctor ? 'text-product-gray-400' : 'text-product-forest-100'
            )}
          >
            {location}
          </p>
          <ul className='p-4 pl-0 pb-0'>
            {appointments.map((appointment: any) => (
              <li
                key={`appointment-schedule-${groupItem.key}-${appointment.id}`}
                className='flex w-full flex-row items-center justify-between pt-2.5'
              >
                <div className='flex items-center'>
                  <CircleIcon className='size-[6px] shrink-0 mr-4 ml-[-1px]' />
                  <p className={cn(TextLayout.body1, 'text-product-gray-400')}>
                    {appointment.title}
                  </p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
